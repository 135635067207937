import React, { useEffect, useState } from "react";
import { AutoFields, EditView, Field } from "@builtbypixel/nucleus";
import { useHttp } from "../../Nucleus/hooks";
import WrapperView from "../../components/CustomEditWrapper";

import setup from "./setup";

const EditForm = () => {
    const Http = useHttp();

    const [branches, setBranches] = useState([]);

    useEffect(() => {
        Http.get("/branches").then((res) => {
            setBranches(res.data?.data);
          });
    }, [])

    return (
        <EditView setup={setup} isFullWidth={false}>
            <WrapperView>

                <Field 
                    name="branch" 
                    component="select"
                    options={branches} 
                    placeholder="Branch" 
                    label="Branch" 
                    valueKey='id'
                    labelKey='name'
                />

                <Field 
                    name="value" 
                    component="text"
                    placeholder="Value" 
                    label="Value"
                />

                <Field 
                    name="fascia" 
                    component="switch"
                    placeholder="Fascia" 
                    label="Fascia"
                />

                <Field
                    name="promotional_products"
                    component="switch"
                    placeholder="Exclude Promotional Products"
                    label="Exclude Promotional Products"
                />

            </WrapperView>
        </EditView>
    );
};

export default EditForm;
