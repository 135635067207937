import React, { useMemo, useEffect, useState } from "react";
import { AutoFields, EditView, debounce, useGet } from "@builtbypixel/nucleus";
import setup from "./setup";
import { useHttp } from "../../Nucleus/hooks";
import useSWR from "swr";
import { useFormContext, useWatch } from "react-hook-form";
import { Box } from "@chakra-ui/react";
import { Field } from "../../Nucleus/components/Form";
import Select from "../../Nucleus/Fields/Select";
import BranchGroupSelector from "./BranchGroupSelector";

const EditForm = () => {
  const fields = useMemo(
    () => [
      {
        group: "",
        fields: [
          {
            name: "banners",
            component: "file",
            label: "Promotion Banner",
            help: "Uploaded images must be 3000px X 1000px resolution or bigger keeping the same 3:1 aspect ratio",
          },
        ],
      },
    ],
    []
  );
  return (
    <EditView setup={setup} isFullWidth>
      <FieldComponent fields={fields} />
      <AutoFields fields={fields} />
    </EditView>
  );
};

const FieldComponent = () => {
  // const { data: groups } = useSWR(`/groups?pagination=99999`, useGet);
  const [defaultProducts, setDefaultProducts] = useState();

  const Http = useHttp();

  const handleGetProducts = debounce((search, callback) => {
    return Http.post(
      `/promoted-products?pagination=9999&search=${search}`
    ).then((res) => {
      callback(res.data.data);
      setDefaultProducts(res.data.data);
    });
  }, 1000);

  // useEffect(() => {
  //   if (branches !== undefined) {
  //     let update_branches = [];
  //     const all_object = { id: 0, name: "All" };
  //     update_branches.push(all_object, ...branches?.data);
  //     setTimeout(() => {
  //       setValue("selected_branches", update_branches);
  //     }, 500);
  //   }
  // }, [branches]);

  // const onChange = (value) => {
  //   Http.get(`/groups/${value?.id}`)
  //     .then((res) => {
  //       let update_groups = [];
  //       const all_object = { id: 0, scheme: "All" };
  //       update_groups.push(all_object, ...res.data.data);
  //       setTimeout(() => {
  //         setValue("selected_groups", update_groups);
  //         setValue("selected_branch", value);
  //       }, 200);
  //     })
  //     .catch((e) => {
  //       console.log(e, "error");
  //     });
  // };

  // const onChangeGroup = (value) => {
  //   setValue("selected_group", value);
  //   setValue("selected_branch.schemas", value);
  // };

  return (
    <Box bg="white" w="100%" display="block">
      <Box padding={5}>
        <Field
          name="name"
          component="text"
          label="Name"
          placeholder="Name"
          isRequired={true}
        />
        <Field
          name="description"
          component="text"
          label="Description"
          placeholder="Description"
          isRequired={true}
        />
        <Field
          name="start_date"
          component="date"
          label="Start Date"
          dateFormat="DD-MM-YYYY"
        />
        <Field
          name="end_date"
          component="date"
          label="End Date"
          dateFormat="DD-MM-YYYY"
        />
        <Field
          name="products"
          component="select"
          placeholder="Select Products"
          isMulti={true}
          loadOptions={handleGetProducts}
          isRequired={true}
          label="Products"
          labelKey="name"
          valueKey="id"
          isAsync="true"
          defaultOptions={defaultProducts ? defaultProducts : null}
          selectValue="id"
          closeOnSelect={false}
          closeMenuOnSelect={false}
        />

        <Field name="is_active" component="switch" label="Acitve on site" />
      </Box>
    </Box>
  );
};

export default EditForm;
