import { createCrudRoutes } from "@builtbypixel/nucleus";
import List from "./List";
import Entry from "./";
import Impressions from "./Impressions";

import setup from "./setup";

const crud = createCrudRoutes({
  setup,
  name: setup.title,
  edit: Entry,
  list: List,
  view: null,
});

const otherRoutes = [
  {
    path: setup.endpoint + "/impressions",
    title: setup.title + " Impressions",
    exact: true,
    auth: true,
    component: Impressions,
  },
];

const routes = [...crud, ...otherRoutes];

export default routes;
