const modelName = "Banner Impressions";
const modelNameSingular = "BannerImpressions";
const endpoint = "/ab-banner-impressions";

const setup = {
  title: modelName,
  singular: modelNameSingular,
  model: modelName.replace(/\s+/g, "-").toLowerCase(),
  canDelete: false,
  canAddNew: false,
  canSearch: false,
  endpoint,
  accessor: "id",
};

export default setup;
