import React, { useEffect, useMemo, useState } from "react";
import { AutoFields, EditView, useHttp, debounce } from "@builtbypixel/nucleus";
import setup from "./setup";

const EditForm = () => {
  const Http = useHttp();

  const handleSearchProducts = debounce((search, callback) => {
    return Http.get(`/products?pagination=9999&search=${search}`).then(
      (res) => {
        callback(res.data.data);
      }
    );
  }, 1000);

  const fields = useMemo(
    () => [
      {
        group: "Ab Testing Details",

        fields: [
          {
            name: "name",
            component: "text",
            rules: { required: true },
            label: "Name",
          },
          {
            name: "products",
            component: "repeater",
            layout: 'vertical',
            fieldArray: [
                {
                    name: "product",
                    component: "select",
                    loadOptions: handleSearchProducts,
                    rules: { required: true },
                    label: "Product",
                    labelKey: "name",
                    valueKey: "id",
                    isAsync: true,
                    selectValue: "id"
                },
                {
                    name: 'image_a',
                    label: 'Image A',
                    component: 'file',
                    max: 1,
                    rules: {required: true}
                },
                {
                    name: 'image_b',
                    label: 'Image B',
                    component: 'file',
                    max: 1,
                    rules: {required: true}
                }
            ]
          }
        ],
      },
    ],
    []
  );

  return (
    <EditView setup={setup} isFullWidth={false}>
      <AutoFields fields={fields} />
    </EditView>
  );
};

export default EditForm;
