import React, { useMemo } from "react";
import { AutoFields, EditView } from "@builtbypixel/nucleus";
import { Text } from "@chakra-ui/react";

import setup from "./setup";

const EditForm = () => {
  const fields = useMemo(
    () => [
      {
        group: "",
        fields: [
          {
            name: "",
            component: () => <Text fontSize="30px">Edit Settings</Text>,
          },
          {
            name: "minOrderTotal",
            component: "number",
            label: "Minimum Order Amount",
          },
          {
            name: "popularSearchDistance",
            component: "number",
            label: "Popular in Area Distance",
          },
          {
            name: "carouselBannerColour",
            component: "colour",
            label: "Carousel Background Colour",
          },
          {
            name: "",
            component: () => <Text>Category Dropdown banner</Text>,
          },
          {
            name: "categoriesBanner",
            component: "file",
            Label: "Categories Dropdown Banner",
            multiple: false,
          },
          {
            name: "categoriesBannerLink",
            component: "text",
            label: "Banner Link",
          },
        ],
      },
    ],
    []
  );

  return (
    <EditView
      setup={setup}
      isFullWidth={false}
      isSingle={true}
      hideHeaderButtons={true}
    >
      <AutoFields fields={fields} />
    </EditView>
  );
};

export default EditForm;
