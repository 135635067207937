import {
    Box, Button, useDisclosure, Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton, Textarea
} from '@chakra-ui/react';
import { useFormContext } from "react-hook-form";
import { FiPlus } from "react-icons/fi";
import { useEffect, useState } from "react";
import { useHttp } from "../../../Nucleus";
const BulkEditModal = ({ formProducts, disable }) => {
    const Http = useHttp();
    const [products, setProducts] = useState('')
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [notExists, setNonExists] = useState(null);
    const { setValue } = useFormContext();
    const handleSave = async () => {
        console.log(products.split(/\r?\n|,/))
        const product_ids = products.split(/\r?\n|,/).filter(item => item.length >= 1);
        const res = await Http.post('/qr_codes_check', {
            product_ids
        });
        setNonExists(res.data.data.filter(item => !item.exists));
        setValue("products", (formProducts ?? []).concat(res.data.data.filter(item => item.exists)))
    }
    useEffect(() => {
        if (!isOpen) {
            setNonExists(null);
            setProducts('')
        }
    }, [isOpen]);
    return (
        <Box>
            <Button
                variant="solid"
                colorScheme="blue"
                leftIcon={<FiPlus />}
                onClick={onOpen}
                isDisabled={disable}
            >Bulk Add</Button>
            <Modal isOpen={isOpen} onClose={onClose} size="auto">
                <ModalOverlay />
                <ModalContent w="80%">
                    <ModalHeader>Bulk Add</ModalHeader>
                    <ModalBody>
                        {!notExists &&
                            <Textarea onChange={e => setProducts(e.target.value)} value={products} minHeight="400px" />
                        }
                        {notExists &&
                            <>
                                <label>Could not find these ids: </label>
                                <Textarea disabled value={notExists.map(item => item.id).join(', ')} minHeight="400px" />
                            </>
                        }
                    </ModalBody>
                    <ModalFooter>
                        {!notExists &&
                            <Button colorScheme='green' onClick={() => handleSave()}>
                                Save{" "}
                            </Button>
                        }
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    )
};
export default BulkEditModal;