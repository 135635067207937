import React, { useState, useEffect } from "react";
import { Flex, Box, Text, Divider } from "@chakra-ui/react";
import { useHttp } from "../../Nucleus/hooks";
import { useFormContext, useFieldArray, useForm } from "react-hook-form";
import Select from "../../Nucleus/Fields/Select";

const Schemes = (props) => {
  const Http = useHttp();
  const [groups, setGroups] = useState([]);
  useEffect(() => {
    Http.get("/groups").then((res) => {
      setGroups(res.data.data);
    });
  }, []);
  const { setValue } = useFormContext();
  const schemesArr = props.value;
  return (
    <Box>
      <Text>Schemes custom component</Text>
      {groups.map((item) => {
        // const branches = item.branches.map((branch) => {
        //   branch.label = branch.name;
        //   delete branch.name;
        //   return branch;
        // });
        // console.log(branches, "<-- branches");
        return (
          <Box my="20px">
            <Select value={item.scheme} options={[]} />
            <Select
              options={item.branches}
              value={item.branches[0]}
              labelKey="name"
              valueKey="id"
            />
            <Divider mt="10px" />
          </Box>
        );
      })}
    </Box>
  );
};

export default Schemes;
