import React, { useMemo } from "react";
import { AutoFields, EditView, Field } from "@builtbypixel/nucleus";
import WrapperView from "../../components/CustomEditWrapper";
import ProductsContainer from "../../components/ProductsContainer";

import setup from "./setup";

const EditForm = () => {
  return (
    <EditView setup={setup} isFullWidth={false}>
      <WrapperView>
        <Field name="name" component="text" placeholder="name" label="Name" />

        <Field
          name="start_date"
          component="date"
          label="Start Date"
          dateFormat="DD-MM-YYYY"
        />

        <Field
          name="end_date"
          component="date"
          label="End Date"
          dateFormat="DD-MM-YYYY"
        />

        <Field name="images" component="file" Label="Images" multiple={true} />

        <ProductsContainer />
      </WrapperView>
    </EditView>
  );
};

export default EditForm;
