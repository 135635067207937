import React, { useMemo } from 'react';
import { LinkButton, ListWrapper } from '@builtbypixel/nucleus';

import { IconButton } from '@chakra-ui/react';
import { FiEdit } from 'react-icons/fi';
import setup from './setup';
import { Button } from '@chakra-ui/react';
// Extra Menu appears next to the create button
const extraMenu = () => {
    return null;
};

const columns = [
    {
        Header: 'Version',
        id: 'edit',
        accessor: 'version',
        Cell: ({ row }) => (
        <LinkButton to={`${setup.model}/edit/${row.original[setup.accessor]}`}>
            <Button variant="link" variantColor="secondary" rounded="md">
            {row.original.version}
            </Button>
        </LinkButton>
        ),
    },
    {
        Header: 'Active',
        accessor: 'active',
        Cell: ({ row }) => (
            row?.original.active === true ? 'Yes' : 'No'
        ),
    },
];

const List = () => {
    
    return (
        <ListWrapper
            setup={setup}
            columns={columns}
            extraMenu={extraMenu}
            handleRowSelection={(rows) => console.log(rows)}
        />
    );
};

export default List;
