const modelName = "Supplier Logs";
const modelNameSingular = "Log";
const endpoint = "/supplier-logs";

const setup = {
  title: modelName,
  singular: modelNameSingular,
  model: modelName.replace(/\s+/g, "-").toLowerCase(),
  canDelete: false,
  canAddNew: false,
  canSearch: true,
  endpoint,
  accessor: "id",
};

export default setup;
