import React from 'react';

import { Container } from '@chakra-ui/react';
import { EditView, Field, useGet} from '@builtbypixel/nucleus';
import setup from './setup';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import useSWR from "swr";

const EditForm = () => {
    const { type } = useParams();

    const { data: types } = useSWR(`/testimonial-types`, useGet);

    return (
        <EditView setup={setup}>
            <Container
                maxWidth='1000px'
                w='100%'
                p='20px'
                margin=' 25px auto'
                variant='card'
            >
                <Field
                    auto
                    isFullWidth
                    name='name'
                    component='text'
                    label='Name'
                    isRequired
                    rules={{ required: true }}
                />

                <Field
                    auto
                    isFullWidth
                    name='position'
                    component='text'
                    label='Position'
                    isRequired
                    rules={{ required: true }}
                />
                <Field
                    name='type'
                    component='select'
                    options={types?.data}
                    label='Testimonial Type'
                    labelKey='name'
                    valueKey='id'
                    rules={{ required: true }}
                />
                <Field
                    auto
                    isFullWidth
                    name='content'
                    component='richtext'
                    label='Content'
                    isRequired
                    rules={{ required: true }}
                />
                <Field
                    auto
                    isFullWidth
                    name='image'
                    component='file'
                    label='Image'
                    isRequired
                    rules={{ required: true }}
                />
            </Container>
        </EditView>
    );
};

export default EditForm;
