// Example route/model.  Remove me!
import announcements from "./containers/Announcements/routes";
import adminUsers from "./containers/AdminUsers/routes";
import messages from "./containers/Messages/routes";
import customers from "./containers/Customers/routes";
import categories from "./containers/Categories/routes";
import brands from "./containers/Brands/routes";
import tags from "./containers/Tags/routes";
import events from "./containers/Events/routes";
import promotions from "./containers/Promotions/routes";
import super_promotions from "./containers/Super_promotions/routes";
import carousels from "./containers/Carousels/routes";
import stock_quantity from "./containers/Stocks_quantity/routes";
import stock_thresholds from "./containers/Stock_thresholds/routes";
import homepage_products from "./containers/Homepage_products/routes";
import homepage_categories from "./containers/Homepage_categories/routes";
import miss_out from "./containers/Miss_out/routes";
import pages from "./containers/Pages/routes";
import resource_categories from "./containers/Resource_categories/routes";
import Dashboard from "./containers/Dashboard/routes";
import purchase_limits from "./containers/Purchase_limits/routes";
import settings from "./containers/Settings/routes";
import products from "./containers/Products/routes";
import supplier_logs from "./containers/Supplier_logs/routes";
import ab_testing from "./containers/AB_Testing/Product/routes";
import ab_bnn_testing from "./containers/AB_Testing/Banner/routes";
import ab_impressions from "./containers/AB_Testing/ProductImpressions/routes";
import ab_banner_impressions from "./containers/AB_Testing/BannerImpressions/routes";
import testimonials from "./containers/Testimonials/routes";
import own_label_range from "./containers/OwnLabelRange/routes";
import app_versions from "./containers/AppVersions/routes";
import minimum_quantities from "./containers/MinimumQuantities/routes";
import minimum_quantities_customers from "./containers/MinimumQuantitiesCustomers/routes";
import promoted_categories from "./containers/PromotedCategories/routes";
import event_suppliers from "./containers/EventsSuppliers/routes";
import app_events from "./containers/AppEvents/routes";
import app_event_suppliers from "./containers/AppEventSuppliers/routes";
import app_event_impressions from "./containers/AppEventImpressions/routes";
import app_event_trolleys from "./containers/AppEventTrolleys/routes";
import qr_codes from './containers/QR_codes/routes';
import interstitialAds from "./containers/InterstitialAds/routes";
import pdf_links from "./containers/PDFLinks/routes";
import promotion_calendar from './containers/PromotionCalendar/routes';

const routes = [
  ...announcements,
  ...adminUsers,
  ...messages,
  ...customers,
  ...categories,
  ...brands,
  ...tags,
  ...events,
  ...promotions,
  ...carousels,
  ...stock_quantity,
  ...stock_thresholds,
  ...homepage_products,
  ...homepage_categories,
  ...miss_out,
  ...pages,
  ...resource_categories,
  ...Dashboard,
  ...purchase_limits,
  ...settings,
  ...products,
  ...supplier_logs,
  ...super_promotions,
  ...ab_testing,
  ...ab_bnn_testing,
  ...testimonials,
  ...own_label_range,
  ...app_versions,
  ...minimum_quantities,
  ...minimum_quantities_customers,
  ...promoted_categories,
  ...event_suppliers,
  ...app_events,
  ...app_event_suppliers,
  ...app_event_impressions,
  ...app_event_trolleys,
  ...qr_codes,
  ...ab_impressions,
  ...ab_banner_impressions,
  ...interstitialAds,
  ...pdf_links,
  ...promotion_calendar,
];

export default routes;
