import React from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";
import { Field } from "@builtbypixel/nucleus";

const WatchedField = ({ data }) => {
  return (
    <Table variant='simple'>
      <Thead>
        <Tr>
          <Th>Text</Th>
          <Th>Text on order</Th>
          <Th>Top Threshold</Th>
          <Th>Bottom Threshold</Th>
        </Tr>
      </Thead>
      <Tbody>
        {data?.map((row, i) => (
          <Tr>
            <Td>
              <Field
                auto
                isFullWidth
                name={`${i}.text`}
                component='text'
                defaultValue={row.text}
              />
            </Td>
            <Td>
              <Field
                auto
                isFullWidth
                name={`${i}.text_on_order`}
                component='text'
                defaultValue={row.text_on_order}
              />
            </Td>

            <Td>
              <Field
                auto
                isFullWidth
                name={`${i}.top`}
                component='text'
                defaultValue={parseInt(row.top)}
              />
            </Td>
            <Td>
              <Field
                auto
                isFullWidth
                name={`${i}.bottom`}
                component='text'
                defaultValue={parseInt(row.bottom)}
              />{" "}
            </Td>
          </Tr>
        ))}
      </Tbody>
      {/* <Tfoot>
        <Tr>
          <Th>To convert</Th>
          <Th>into</Th>
          <Th isNumeric>multiply by</Th>
        </Tr>
      </Tfoot> */}
    </Table>
  );
};

export default WatchedField;
