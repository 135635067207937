import React, { useEffect } from "react";
import { useWatch, useFormContext } from "react-hook-form";
import { Flex, Box, Text } from "@chakra-ui/react";
import SearchBar from "./SearchBar";
import ProductCard from "./ProductCard";
import { useState } from "react";
import AddProductsModal from "./AddProductsModal";
import BulkEditModal from "./BulkEditModal";

const ProductsContainer = ({ custom, bulkEdit = false }) => {
  const [products, setProducts] = useState([]);
  const [disable, setDisabled] = useState(false);
  const { setValue, getValue } = useFormContext();
  const formProducts = useWatch({
    name: "products",
  });

  const promId = useWatch({
    name: "prom_id",
  });

  useEffect(() => {
    if (formProducts) {
      setProducts(formProducts);
      setValue(
        "product_ids",
        formProducts.map((el) => el.id)
      );
    }
  }, [formProducts]);

  useEffect(() => {
    if (promId) {
      setDisabled(true);
      setValue("products", undefined);
    }
  }, [promId]);

  const removeItem = (item) => {
    const redactedList = formProducts.filter(
      (product) => product.id !== item.id
    );
    setValue("products", redactedList);
    setValue(
      "product_ids",
      redactedList.map((el) => el.id)
    );
  };
  return (
    <Box mt='25px'>
      <Text fontWeight='bold'>Products</Text>
      <Flex justify='space-between' flexDir="row-reverse">
        <SearchBar
          original={formProducts}
          products={products}
          setProducts={setProducts}
        />
        <Flex flexDirection="row" gridGap="12px">
          {bulkEdit && (
            <BulkEditModal disable={disable} formProducts={formProducts} />
          )}
          <AddProductsModal disable={disable} original={formProducts} />
        </Flex>
      </Flex>

      {products && products.length > 0 ? (
        <Flex wrap='wrap' mt='20px'>
          {products.map((product) => (
            <ProductCard product={product} removeItem={removeItem} />
          ))}
        </Flex>
      ) : (
        "no products found"
      )}
    </Box>
  );
};

export default ProductsContainer;
