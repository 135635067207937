import React from "react";
import { ListWrapper } from "@builtbypixel/nucleus";
import { Tag } from "@chakra-ui/react";
import setup from "./setup";

const extraMenu = () => null;

const List = ({ customSelected }) => {
  const columns = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Brand",
      accessor: "brand",
      Cell: ({ value }) => (value?.name ? value?.name : "-"),
    },
    {
      Header: "Categories",
      accessor: "categories",
      Cell: ({ value }) =>
        value?.length > 0
          ? value?.map((category) => (
              <Tag colorScheme={"blue"} mx="2px">
                {category.name}
              </Tag>
            ))
          : "-",
    },
  ];

  return (
    <ListWrapper
      setup={setup}
      columns={columns}
      extraMenu={extraMenu}
      customSelected={customSelected}
    />
  );
};

export default List;
