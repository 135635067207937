import { Button, Flex, Image } from "@chakra-ui/react";
import React from "react";
import { useWatch } from "react-hook-form";
const QRcode = () => {
  const qrcode = useWatch({ name: "image" });
  const name = useWatch({ name: "name" });
  const handleDownload = () => {
    const link = document.createElement("a");
    link.download = `${name}.svg`;
    link.target = "_blank";
    link.href = qrcode;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return qrcode ? (
    <Flex flexDir={"column"}>
      <Image src={qrcode} />
      <Button mt="3" onClick={handleDownload}>
        Download Code
      </Button>
    </Flex>
  ) : (
    "N/a"
  );
};
export default QRcode;