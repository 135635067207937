import { EditView } from "@builtbypixel/nucleus";

import setup from "./setup";
import React, { useMemo, useEffect, useState } from "react";
import { AutoFields, debounce, useGet } from "@builtbypixel/nucleus";

import { useHttp } from "../../Nucleus/hooks";
import useSWR from "swr";
import { useFormContext, useWatch } from "react-hook-form";
import { Box } from "@chakra-ui/react";
import { Field } from "../../Nucleus/components/Form";
import Select from "../../Nucleus/Fields/Select";
import { Text } from "@chakra-ui/react";
import PromotedCategories from "./components/PromotedCategories";
const EditForm = () => {
  const FieldComponent = () => {
    // const { data: groups } = useSWR(`/groups?pagination=99999`, useGet);
    const [defaultProducts, setDefaultProducts] = useState();

    const Http = useHttp();

    const handleGetProducts = debounce((search, callback) => {
      return Http.get(`/categories?pagination=9999&search=${search}`).then(
        (res) => {
          callback(res.data);
        }
      );
    }, 1000);
    const handleGetDefault = () => {
      return Http.get(`/categories`).then((res) => {
        console.log(res);
        setDefaultProducts(res.data.data);
        console.log(defaultProducts, "deafault");
      });
    };
    useEffect(() => {
      handleGetDefault();
    }, []);

    // useEffect(() => {
    //   if (branches !== undefined) {
    //     let update_branches = [];
    //     const all_object = { id: 0, name: "All" };
    //     update_branches.push(all_object, ...branches?.data);
    //     setTimeout(() => {
    //       setValue("selected_branches", update_branches);
    //     }, 500);
    //   }
    // }, [branches]);

    // const onChange = (value) => {
    //   Http.get(`/groups/${value?.id}`)
    //     .then((res) => {
    //       let update_groups = [];
    //       const all_object = { id: 0, scheme: "All" };
    //       update_groups.push(all_object, ...res.data.data);
    //       setTimeout(() => {
    //         setValue("selected_groups", update_groups);
    //         setValue("selected_branch", value);
    //       }, 200);
    //     })
    //     .catch((e) => {
    //       console.log(e, "error");
    //     });
    // };

    // const onChangeGroup = (value) => {
    //   setValue("selected_group", value);
    //   setValue("selected_branch.schemas", value);
    // };

    return (
      <Box bg="white" w="100%" px="20px" py="50px" display="block">
        <Text fontSize="30px">Promoted Categories</Text>
        <Box padding={5}>
          <Field
            name="corporate_top_selected"
            component="select"
            placeholder="Select Categories"
            isMulti={true}
            loadOptions={handleGetProducts}
            isRequired={true}
            label="Feed Categories"
            labelKey="name"
            valueKey="id"
            isAsync="true"
            selectValue="id"
            closeOnSelect={false}
            closeMenuOnSelect={false}
            defaultOptions={defaultProducts ? defaultProducts : null}
          />
        </Box>
      </Box>
    );
  };
  return (
    <EditView
      setup={setup}
      isFullWidth={false}
      hideHeaderButtons={true}
      hideSubmit
    >
      <PromotedCategories />
      {/* <FieldComponent /> */}
    </EditView>
  );
};

export default EditForm;
