const modelName = 'Minimum Quantities'
const modelNameSingular = 'Minimum Quantity'
const endpoint = '/minimum-quantities'

const setup = {
  title: modelName,
  singular: modelNameSingular,
  model: modelName.replace(/\s+/g, '-').toLowerCase(),
  canDelete: true,
  canAddNew: true,
  canSearch: true,
  endpoint,
  accessor: 'id'
}

export default setup
