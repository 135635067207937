import React, { useMemo } from "react";
import { AutoFields, EditView } from "@builtbypixel/nucleus";

import setup from "../setup";

const EditForm = () => {
  const fields = useMemo(
    () => [
      {
        group: "General",
        tab: "General",
        fields: [
          {
            name: "name",
            component: "text",
            placeholder: "Name",
            label: "Page Name",
          },
          {
            name: "url",
            component: "text",
            placeholder: "e.g. /products",
            label: "Page URL",
          }
        ],
      },
      {
        group: 'Banner Image',
        tab: 'Banner Image',
        fields: [
          {
            name: 'content.banner_image',
            component: 'file',
            label: 'Banner Image',
            required: true
          }
        ]
      },
      {
        group: 'Our Symbol Group',
        tab: 'Our Symbol Group',
        fields: [
          {
            name: 'content.title',
            component: 'richtext',
            label: 'Title',
            required: true
          },
          {
            name: 'content.description',
            component: 'richtext',
            label: 'Description',
            required: true
          },
        ]
      },
      {
        group: 'Our Symbol Group Includes',
        tab: 'Our Symbol Group Includes',
        fields: [
          {
            name: 'content.includes_title',
            component: 'text',
            label: 'Title',
            required: true
          },
          {
            name: 'content.includes',
            component: 'repeater',
            layout: 'vertical',
            label: 'Includes',
            fieldArray: [
              {
                name: 'image',
                component: 'file',
                label: 'Image'
              },
              {
                name: 'link',
                component: 'text',
                label: 'Link'
              }
            ],
            required: true
          }
        ]
      },
      {
        group: 'Retailers Carousel',
        tab: 'Retailer Carousel',
        fields: [
          {
            name: 'content.retailer_title',
            component: 'text',
            label: 'Title'
          }
        ]
      },
      {
        group: "SEO",
        tab: "SEO",
        fields: [
          {
            name: "seo_title",
            component: "text",
            label: "SEO title"
          },
          {
            name: "seo_description",
            component: "textarea",
            label: "SEO description"
          }
        ]
      }
    ],
    []
  );

  return (
    <EditView setup={setup} isFullWidth={false}>
      <AutoFields fields={fields} />
    </EditView>
  );
};

export default EditForm;
