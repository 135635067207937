import React from 'react'
import { useColorMode, Box } from '@chakra-ui/react'

const RepeaterConditionalFieldWrapper = ({ children, index, i, component }) => {
   const { colorMode } = useColorMode()
   return (
      <Box
         mb='0px'
         isFile={component === 'file'}
         rounded='md'
         bg={
            colorMode === 'light'
               ? 'rgba(255,255,255,0.4)'
               : 'rgba(255,255,255,0.05)'
         }
         overflow='hidden'
         position='relative'
      >
         {children}
      </Box>
   )
}

export default RepeaterConditionalFieldWrapper
