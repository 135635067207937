import React, { useEffect, useState } from 'react';
import { EditView, Field, debounce } from '@builtbypixel/nucleus';
import setup from './setup';
import { useParams } from 'react-router-dom';
import WrapperView from "../../components/CustomEditWrapper";
import TrolleyDetails from "../../components/TrolleyDetails";

const EditForm = () => {
    return (
        <EditView setup={setup}>
            <WrapperView>
                <TrolleyDetails setup={setup} />   
            </WrapperView>
        </EditView>
    );
};

export default EditForm;
