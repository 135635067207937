import React from "react";
import { Flex, Container } from "@chakra-ui/react";

const WrapperView = ({ children }) => (
  <Flex overflowX='hidden' w='100%'>
    <Flex p='0px' w='100%' minHeight='100vh' justify='flex-start'>
      <Container
        p={{ base: "10px", sm: "15px" }}
        w='100%'
        variant='card'
        rounded='none'
        maxWidth='none'
      >
        {children}
      </Container>
    </Flex>
  </Flex>
);

export default WrapperView;
