const modelName = 'App Event Impressions'
const modelNameSingular = 'App Event Impression'
const endpoint = '/app-event-impressions'

const setup = {
  title: modelName,
  singular: modelNameSingular,
  model: modelName.replace(/\s+/g, '-').toLowerCase(),
  canDelete: false,
  canAddNew: false,
  canSearch: true,
  endpoint,
  accessor: 'id'
}

export default setup
