import React from 'react';
import { LinkButton, ListWrapper } from '@builtbypixel/nucleus';
import { Button, Image, Text } from '@chakra-ui/react';
import setup from './setup';
// Extra Menu appears next to the create button.  You can call any component you wish.
const extraMenu = () => null;
const List = () => {
  const columns = [

    {
      Header: '',
      accessor: 'image',
      Cell: ({ value }) => (
        <Image
          alt='Image'
          fallbackSrc='https://via.placeholder.com/150'
          mr='20px'
          objectFit='cover'
          size='50px'
          src={value}
        />
      ),
    },
    {
      Header: 'Name',
      accessor: 'Name',
      Cell: ({ row }) => (
        <LinkButton to={`${setup.model}/edit/${row.original[setup.accessor]}`}>
          <Button variant='link' variantColor='secondary' rounded='md'>
            {row.original.name}
          </Button>
        </LinkButton>
      ),
    },
    {
      Header: 'Products Count',
      accessor: 'count',
    },
  ];
  return <ListWrapper setup={setup} columns={columns} extraMenu={extraMenu} />;
};
export default List;