import React, { useMemo } from "react";
import { LinkButton, ListWrapper } from "@builtbypixel/nucleus";

import { Box, Flex, IconButton } from "@chakra-ui/react";
import { FiEdit } from "react-icons/fi";
import setup from "./setup";
import { Button } from "@chakra-ui/react";
// Extra Menu appears next to the create button
const extraMenu = () => {
  return null;
};

const calculatePercentage = (a, b) => {
  if (parseInt(a, 10) === 0) {
    return "0%";
  }

  const num = ((parseInt(b, 10) / parseInt(a, 10)) * 100).toFixed(2);

  return isNaN(num) ? "0%" : num + "%";
};

const columns = [
  {
    Header: "Name",
    id: "edit",
    accessor: "name",
  },
  {
    Header: "Image A (Views | Clicks | Click Rate | Trolleys | Trolley Rate)",
    Cell: ({ row }) => (
      <Flex flexDir="column">
        <Box>
          {row.original.image_a_views} | {row.original.image_a_clicks} |{" "}
          {calculatePercentage(
            row.original.image_a_views,
            row.original.image_a_clicks
          )}{" "}
          | {row.original.image_a_trolley} |{" "}
          {calculatePercentage(
            row.original.image_a_views,
            row.original.image_a_trolley
          )}
        </Box>
      </Flex>
    ),
  },
  {
    Header: "Image B (Views | Clicks | Click Rate | Trolleys | Trolley Rate)",
    Cell: ({ row }) => (
      <Flex flexDir="column">
        <Box>
          {row.original.image_b_views} | {row.original.image_b_clicks} |{" "}
          {calculatePercentage(
            row.original.image_b_views,
            row.original.image_b_clicks
          )}{" "}
          | {row.original.image_b_trolley} |{" "}
          {calculatePercentage(
            row.original.image_b_views,
            row.original.image_b_trolley
          )}
        </Box>
      </Flex>
    ),
  },
];

const List = () => {
  const filters = useMemo(
    () => [
      {
        name: "from_date",
        component: "date",
        placeholder: "From",
        dateFormat: "DD / M / YYYY",
      },
      {
        name: "to_date",
        component: "date",
        placeholder: "To",
        dateFormat: "DD / M / YYYY",
      },
    ],
    []
  );

  return (
    <ListWrapper
      filters={filters}
      setup={setup}
      columns={columns}
      extraMenu={extraMenu}
      handleRowSelection={(rows) => console.log(rows)}
    />
  );
};

export default List;
