const Container = {
   baseStyle: {},
   variants: {
      Container: ({ colorMode }) => ({
         bg: colorMode === 'light' ? 'white' : 'gray.800'
      }),
      card: {
         bg: 'white',
         rounded: '8px'
      }
   }
}

export default Container
