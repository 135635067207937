import React, { useMemo } from "react";
import { ListWrapper } from "@builtbypixel/nucleus";

import setup from "./setup";
import { Box, Flex } from "@chakra-ui/react";
// Extra Menu appears next to the create button

const calculatePercentage = (a, b) => {
  const num = ((parseInt(b, 10) / parseInt(a, 10)) * 100).toFixed(2);

  return isNaN(num) ? "0%" : num + "%";
};

const columns = [
  {
    Header: "Name",
    id: "edit",
    accessor: "name",
  },
  {
    Header: "Image A (Views | Clicks | Click Rate)",
    Cell: ({ row }) => (
      <Flex flexDir="column">
        <Box>
          {row.original.image_a_views} | {row.original.image_a_clicks} |{" "}
          {calculatePercentage(
            row.original.image_a_views,
            row.original.image_a_clicks
          )}
        </Box>
      </Flex>
    ),
  },
  {
    Header: "Image B (Views | Clicks | Click Rate)",
    Cell: ({ row }) => (
      <Flex flexDir="column">
        <Box>
          {row.original.image_b_views} | {row.original.image_b_clicks} |{" "}
          {calculatePercentage(
            row.original.image_b_views,
            row.original.image_b_clicks
          )}
        </Box>
      </Flex>
    ),
  },
];

const List = () => {
  const filters = useMemo(
    () => [
      {
        name: "from_date",
        component: "date",
        placeholder: "From",
        dateFormat: "DD / M / YYYY",
      },
      {
        name: "to_date",
        component: "date",
        placeholder: "To",
        dateFormat: "DD / M / YYYY",
      },
    ],
    []
  );

  return <ListWrapper setup={setup} columns={columns} filters={filters} />;
};

export default List;
