import React, { useEffect, useState } from "react";
import {
  AutoFields,
  EditView,
  Field,
  debounce,
  useHttp,
} from "@builtbypixel/nucleus";
import WrapperView from "../../components/CustomEditWrapper";

import setup from "./setup";

const EditForm = () => {
  const Http = useHttp();

  const handleSearchCustomers = debounce((search, callback) => {
    return Http.get(`/customers?pagination=9999&search=${search}`).then(
      (res) => {
        callback(res.data.data);
      }
    );
  }, 1000);

  return (
    <EditView setup={setup} isFullWidth={false}>
      <WrapperView>
        <Field
          name="customer"
          component="select"
          loadOptions={handleSearchCustomers}
          label="Customer"
          labelKey="business_name"
          valueKey="id"
          isAsync="true"
          selectValue="id"
          isHorizontal
        />

        <Field
          name="value"
          component="text"
          placeholder="Value"
          label="Value"
        />

        <Field
          name="promotional_products"
          component="switch"
          placeholder="Exclude Promotional Products"
          label="Exclude Promotional Products"
        />
      </WrapperView>
    </EditView>
  );
};

export default EditForm;
