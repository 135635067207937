const modelName = 'App Event Suppliers'
const modelNameSingular = 'App Event Supplier'
const endpoint = '/app-event-suppliers'

const setup = {
  title: modelName,
  singular: modelNameSingular,
  model: modelName.replace(/\s+/g, '-').toLowerCase(),
  canDelete: false,
  canAddNew: false,
  canSearch: true,
  endpoint,
  accessor: 'id'
}

export default setup
