import React, { useEffect, useState } from 'react';
import { useHttp } from '../../Nucleus/hooks/useHttp';
import { Box, Container, Grid, GridItem, Text, Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import { EditView, Field, debounce } from '@builtbypixel/nucleus';
import setup from './setup';
import { useParams } from 'react-router-dom';
import ProductsContainer from "../../components/ProductsContainer";
import WrapperView from "../../components/CustomEditWrapper";

const EditForm = () => {
    const { type } = useParams();
    const Http = useHttp()
    const [suppliers, setSuppliers] = useState([]);

    useEffect(() => {
        Http.get(`/event-suppliers?pagination=9999`).then(
            (res) => {
                setSuppliers(res?.data?.data)
            }
        )
    }, [])

    const handleSearchGroups = debounce((search, callback) => {
        return Http.get(`/all-groups?pagination=9999&search=${search}`).then(
          (res) => {
            callback(res.data.data);
          }
        );
      }, 1000);

    return (
        <EditView setup={setup}>
            <WrapperView>
                <Tabs>
                    <TabList>
                        <Tab>Event Details</Tab>
                        <Tab>Event Customers</Tab>
                        <Tab>Event Suppliers</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <Field
                                auto
                                name='name'
                                component='text'
                                label='Name'
                                isRequired
                                rules={{ required: true }}
                            />
                            <Grid templateColumns='repeat(2, 1fr)' gap={4}>
                                <GridItem>
                                    <Field
                                        auto
                                        name='address'
                                        component='text'
                                        label='Address'
                                        isRequired
                                        help="Please Enter The Full Address Including Postcode"
                                    />
                                </GridItem>
                                <GridItem>
                                    <Field
                                        auto
                                        name='group'
                                        component='select'
                                        label='Price Scheme'
                                        loadOptions={handleSearchGroups}
                                        isAsync={true}
                                        formatOptionLabel={(group) => (
                                            <Box>
                                            <Text>{group.scheme} ({group?.branch?.name})</Text>
                                            </Box>
                                        )}
                                        labelKey='scheme'
                                        valueKey='id'
                                        help="Please Select The Price Scheme Which Will Be Used For This Event"
                                    />
                                </GridItem>
                            </Grid>
                            
                            <Grid templateColumns='repeat(2, 1fr)' gap={4}>
                                <GridItem>
                                    <Field
                                        auto
                                        name='start_date'
                                        component='date'
                                        label='Start Date'
                                    />
                                </GridItem>
                                <GridItem>
                                    <Field
                                        auto
                                        name='end_date'
                                        component='date'
                                        label='End Date'
                                    />
                                </GridItem>
                            </Grid>

                            <Field
                                auto
                                name='image'
                                component='file'
                                label='Image'
                                isRequired
                                max={1}
                                rules={{ required: true }}
                            />
                            <Field
                                auto
                                isFullWidth
                                name='active'
                                component='switch'
                                label='Active'
                            />

                            <Field
                                auto
                                isFullWidth
                                name='online'
                                component='switch'
                                label='Online'
                            />
                        </TabPanel>
                        <TabPanel>
                            <Field
                                auto
                                isFullWidth
                                name='customers'
                                component='textarea'
                                label='Customers'
                            />
                        </TabPanel>
                        <TabPanel>
                            <Field
                                auto
                                isFullWidth
                                name='suppliers'
                                component='repeater'
                                label='Suppliers'
                                layout='vertical'
                                fieldArray={[
                                    {
                                        name: 'supplier',
                                        component: 'select',
                                        label: 'Supplier',
                                        options: suppliers,
                                        labelKey: 'name',
                                        valueKey: 'id',
                                        isRequired: true,
                                        rules: {required: true}
                                    },
                                    {
                                        name: 'products',
                                        component: 'textarea',
                                        label: 'Products',
                                        isRequired: true,
                                        rules: {required: true}
                                    }
                                ]}
                            />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </WrapperView>
        </EditView>
    );
};

export default EditForm;
