import { EditView } from '@builtbypixel/nucleus';
import OwnLabelRange from './components/OwnLabelRange';
import setup from './setup';

const EditForm = () => {
  return (
    <EditView setup={setup} isFullWidth={false}>
      <OwnLabelRange />
    </EditView>
  );
};

export default EditForm;
