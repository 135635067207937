import React from 'react'
import { Box, Text, Alert, AlertIcon } from '@chakra-ui/react'
import { useRecoilValue } from 'recoil'
import { formAtom } from '../../state/form'

const ErrorMessage = () => {
   const { errors } = useRecoilValue(formAtom)

   return errors ? (
      <Box w='100%' p='5px'>
         <Alert
            status='error'
            variant='solid'
            w='100%'
            title='Form did not submit successfully'
            rounded='5px'
         >
            <AlertIcon />
            <Text fontSize='sm' fontWeight='semibold'>
               There was an error submitting your request
            </Text>
         </Alert>
      </Box>
   ) : null
}

export default ErrorMessage
