import React, { useEffect, useMemo, useState } from "react";
import { AutoFields, EditView, useHttp, debounce } from "@builtbypixel/nucleus";
import setup from "./setup";

const EditForm = () => {
  const Http = useHttp();

  const handleSearchCustomers = debounce((search, callback) => {
    return Http.get(`/customers?pagination=9999&search=${search}`).then(
      (res) => {
        callback(res.data.data);
      }
    );
  }, 1000);

  const fields = useMemo(
    () => [
      {
        group: "Main Details",

        fields: [
          {
            name: "message",
            component: "textarea",
            rules: { required: true },
            label: "Message",
          },
          {
            name: "date",
            component: "date",
            placeholder: "Select end date",
            label: "End Date",
            dateFormat: "DD/MM/YYYY",
          },
          {
            name: "customer_ids",
            component: "select",
            isMulti: true,
            loadOptions: handleSearchCustomers,
            rules: { required: true },
            label: "Customers",
            labelKey: "business_name",
            valueKey: "id",
            isAsync: true,
            selectValue: "id",
          },
        ],
      },
    ],
    []
  );

  return (
    <EditView setup={setup} isFullWidth={false}>
      <AutoFields fields={fields} />
    </EditView>
  );
};

export default EditForm;
