import React from "react";
import { LinkButton, ListWrapper } from "@builtbypixel/nucleus";
import { Button, Icon } from "@chakra-ui/react";
import setup from "./setup";
import { FaCheck } from "react-icons/fa";

// Extra Menu appears next to the create button.  You can call any component you wish.
// const extraMenu = () => {
//    return (
//       <Flex>
//          <Flex>fdgdsgfddf</Flex>
//       </Flex>
//    )
// }

const extraMenu = () => null;

const List = () => {
  const columns = [
    {
      Header: "Title",
      accessor: "title",
      id: "edit",
      Cell: ({ row, value }) => (
        <LinkButton to={`${setup.model}/edit/${row.original[setup.accessor]}`}>
          <Button variant="link" variantColor="secondary" rounded="md">
            {value}
          </Button>
        </LinkButton>
      ),
    },
    {
      Header: "Active",
      accessor: "is_active",
      Cell: ({ value }) => (value ? <Icon color="green" as={FaCheck} /> : null),
    },
  ];

  return <ListWrapper setup={setup} columns={columns} extraMenu={extraMenu} />;
};

export default List;
