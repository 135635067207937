import React from 'react'
import { useWatch } from "react-hook-form";
import { TableContainer, Text, Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td, Button, Input, Image, Flex } from '@chakra-ui/react';
import EditView from '../../components/CustomEditWrapper';
import WrapperView from '../../components/CustomEditWrapper';

const Index = (setup) => {
    
    const data = useWatch();

    const handleChange = (e) => {
        console.log(e.target.value)
    }


    return (
        <>
            <Text fontSize='xl' p='5'>Trolley Details For Customer {data?.customer?.id}</Text>
            <Flex w='100%' alignContent='center' justifyContent='center'>
            <EditView setup={setup}>
                <WrapperView> 
                    
                </WrapperView>
            </EditView>
                {/* <TableContainer w='80%'>
                    <Table variant='simple'>
                        <Thead>
                            <Tr>
                                <Th>Image</Th>
                                <Th>Product</Th>
                                <Th>Pack</Th>
                                <Th isNumeric>Quantity</Th>
                                <Th></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {data?.trolleyItems?.map((item, index) => (
                                <Tr key={index}>
                                    <Td><Image src={`https://api2.parfetts.co.uk/images/products/${item?.pack?.product?.id}.jpg`} boxSize='100px' alt={item?.pack?.product?.name} /></Td>
                                    <Td>{item?.pack?.product?.name}</Td>
                                    <Td>{item?.pack?.quantity} x {item?.pack?.uos}</Td>
                                    <Td isNumeric><Input
                                        defaultValue={item?.quantity}
                                        onChange={handleChange}
                                        placeholder='Product Quantity'
                                        type='number'
                                    /></Td>
                                    <Td><Button>Update</Button></Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer> */}
            </Flex>
        </>
    )
}

export default Index