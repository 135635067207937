import React, { useMemo } from 'react';
import { LinkButton, ListWrapper } from '@builtbypixel/nucleus';

import { IconButton } from '@chakra-ui/react';
import { FiEdit } from 'react-icons/fi';
import setup from './setup';
import { Button, Text } from '@chakra-ui/react';
// Extra Menu appears next to the create button
const extraMenu = () => {
    return null;
};

const columns = [
    {
        Header: 'Customer',
        id: 'edit',
        accessor: 'customer',
        Cell: ({ row }) => (
        <LinkButton to={`${setup.model}/edit/${row.original[setup.accessor]}`}>
            <Button variant="link" variantColor="secondary" rounded="md">
            {row?.original?.customer?.id + ' - ' + row.original.customer?.business_name}
            </Button>
        </LinkButton>
        ),
    },
    {
        Header: 'Branch',
        accessor: 'branch',
        Cell: ({ row }) => (
            row?.original?.branch?.name
        )
    },
    {
        Header: 'Value',
        accessor: 'value',
        Cell: ({ row }) => (
            '£'+ row?.original?.value
        )
    },
    {
        Header: 'Exclude Promotional Products',
        accessor: 'promotional_products',
        Cell: ({ row }) => (
            row?.original?.promotional_products === true ? <Text color='green'>Yes</Text> : <Text>No</Text>
        )
    }
];

const List = () => {
    
    return (
        <ListWrapper
            setup={setup}
            columns={columns}
            extraMenu={extraMenu}
            handleRowSelection={(rows) => console.log(rows)}
        />
    );
};

export default List;
