import React from 'react';
import { Button } from '@chakra-ui/react';
import { useWatch } from 'react-hook-form';
import { Link } from 'react-router-dom';
const GoButton = () => {
  const url = useWatch({ name: 'url' });
  return (
    <Link to={{ pathname: url }} target='_blank'>
      <Button bg='#e51a22' variant='red' color='white'>
        Go To Link
      </Button>
    </Link>
  );
};
export default GoButton;