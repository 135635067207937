import React, { useEffect, useState } from "react";
import { EditView, Field } from "@builtbypixel/nucleus";
import setup from "./setup";
import ProductsContainer from "../../components/ProductsContainer";
import WrapperView from "../../components/CustomEditWrapper";
import { Router, useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
  AbsoluteCenter,
  Box,
  Button,
  Divider,
  Flex,
  Image,
  Input,
  Text,
} from "@chakra-ui/react";
import QRcode from "./QRcode";
import GoButton from "./GoButton";
import { useHttp } from "../../Nucleus";
const EditForm = () => {
  const { type } = useParams();
  const Http = useHttp();
  const [promoOptions, setPromoOptions] = useState([]);
  useEffect(() => {
    Http.get("/price-promotions").then((res) => {
      setPromoOptions(res.data.data);
    });
  }, []);
  return (
    <EditView setup={setup} isFullWidth={false}>
      <WrapperView>
        <Flex w="100%" justify={"space-between"}>
          <Flex width={"45%"} direction="column">
            <Field
              auto
              isFullWidth
              name="name"
              component="text"
              label="Name"
              isRequired
              rules={{ required: true }}
            />
            <Field
              auto
              isFullWidth
              name="slug"
              component="text"
              label="Reference"
              isRequired
              help="Please ensure there are no spaces"
              rules={{ required: true }}
              disabled={type === "edit"}
            />
            {type === "edit" && (
              <Flex direction="column" mb={"10px"}>
                <Field
                  auto
                  isFullWidth
                  name="url"
                  component="text"
                  label="URL"
                  disabled
                />
                <GoButton />
              </Flex>
            )}
            <Field
              auto
              isFullWidth
              name="prom_id"
              component="select"
              label="Promotion ID"
              options={promoOptions}
              isRequired
              isHorizontal
              labelKey={"name"}
              valueKey={"id"}
              rules={{ required: false }}
            />
          </Flex>
          {type === "edit" && (
            <Flex w="50%" justify="center" align="center">
              <QRcode />
            </Flex>
          )}
        </Flex>
        {/* custom product view*/}
        <Box position="relative" padding="20">
          <Divider />
          <AbsoluteCenter bg="white" px="4" fontWeight={"bold"}>
            Select Your Product(s)
          </AbsoluteCenter>
        </Box>
        <ProductsContainer bulkEdit custom={true} />
      </WrapperView>
    </EditView>
  );
};
export default EditForm;