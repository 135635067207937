import React from "react";
import { LinkButton, ListWrapper } from "@builtbypixel/nucleus";
import { Button,Tag,Tooltip } from "@chakra-ui/react";
import setup from "./setup";
import dayjs from "dayjs";

// Extra Menu appears next to the create button.  You can call any component you wish.
// const extraMenu = () => {
//    return (
//       <Flex>
//          <Flex>fdgdsgfddf</Flex>
//       </Flex>
//    )
// }

const extraMenu = () => null;

const List = () => {
  const columns = [
    {
      Header: "Sent On",
      accessor: "created_at",
      id: "edit",
      Cell: ({ row }) => (
        <LinkButton to={`${setup.model}/edit/${row.original[setup.accessor]}`}>
          <Button variant="link" variantColor="secondary" rounded="md">
            {dayjs(row.original.created_at).format("DD/MM/YY HH:mm")}
          </Button>
        </LinkButton>
      ),
    },
    {
      Header: "Sent To",
      accessor: "customer_ids",
      Cell: ({ value }) => value.length > 0 ? value.map((customer)=><Tooltip label={customer.id}><Tag colorScheme={'green'} mx='2px'>{customer.business_name}</Tag></Tooltip>): "-"
    },
    {
      Header: "Message",
      accessor: "message",
      Cell: ({ value }) => value.substring(0, 100) + "...",
    },
  ];

  return <ListWrapper setup={setup} columns={columns} extraMenu={extraMenu} />;
};

export default List;
