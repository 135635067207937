import React, { useEffect, useState } from 'react';
import { useHttp } from '../../Nucleus/hooks/useHttp';
import { Box, Container, Grid, GridItem, Text, Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import { EditView, Field, debounce } from '@builtbypixel/nucleus';
import setup from './setup';
import { useParams } from 'react-router-dom';
import WrapperView from "../../components/CustomEditWrapper";
import AppEventTimePicker from "../../components/AppEventTimePicker/AppEventTimePicker";
import { useWatch } from 'react-hook-form';

const EditForm = () => {
    const { type } = useParams();
    const Http = useHttp()
    const [suppliers, setSuppliers] = useState([]);
    const [groups, setGroups] = useState([]);

    useEffect(() => {
        Http.get(`/all-event-suppliers`).then(
            (res) => {
                setSuppliers(res?.data?.data)
            }
        )
    }, [])

    useEffect(() => {
        Http.get(`/groups`).then(
            (res) => {
                setGroups(res?.data?.data)
            }
        )
    }, [])

    const handleSearchGroups = debounce((search, callback) => {
        return Http.get(`/groups`).then(
            (res) => {
                callback(res.data.data);
            }
        );
    }, 1000);


    return (
        <EditView setup={setup}>
            <WrapperView>
                <Tabs>
                    <TabList>
                        <Tab>Event Details</Tab>
                        <Tab>Event Customers</Tab>
                        <Tab>Event Suppliers</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <Field
                                auto
                                name='name'
                                component='text'
                                label='Name'
                                isRequired
                                rules={{ required: true }}
                            />
                            <Grid templateColumns='repeat(2, 1fr)' gap={4}>
                                <GridItem>
                                    <Field
                                        auto
                                        name='address'
                                        component='text'
                                        label='Address'
                                        isRequired
                                        help="Please Enter The Full Address Including Postcode"
                                    />
                                </GridItem>
                                <GridItem>
                                    <Field
                                        auto
                                        name='group'
                                        component='select'
                                        label='Price Scheme'
                                        options={groups}
                                        formatOptionLabel={(group) => (
                                            <Box>
                                                <Text>{group.scheme}</Text>
                                            </Box>
                                        )}
                                        labelKey='scheme'
                                        valueKey='id'
                                        help="Please Select The Price Scheme Which Will Be Used For This Event"
                                    />
                                </GridItem>
                            </Grid>

                            <Grid templateColumns='repeat(2, 1fr)' gap={4}>
                                <GridItem>
                                    <Text fontSize={11} fontWeight={600} color='gray.600' mb='3px'>Start Date</Text>
                                    <AppEventTimePicker
                                        placeholder='Start Date'
                                        help='Start Date'
                                        name='start_date'
                                        showTimeSelect
                                        dateFormat='dd/MM/yyyy HH:mm'
                                    />
                                </GridItem>
                                <GridItem>
                                    <Text fontSize={11} fontWeight={600} color='gray.600' mb='3px'>End Date</Text>
                                    <AppEventTimePicker
                                        placeholder='End Date'
                                        help='End Date'
                                        name='end_date'
                                        showTimeSelect
                                        dateFormat='dd/MM/yyyy HH:mm'
                                    />
                                </GridItem>
                            </Grid>

                            <Field
                                auto
                                name='image'
                                component='file'
                                label='Image'
                                isRequired
                                max={1}
                                rules={{ required: true }}
                            />
                            <Field
                                auto
                                isFullWidth
                                name='active'
                                component='switch'
                                label='Active'
                            />

                            <Field
                                auto
                                isFullWidth
                                name='online'
                                component='switch'
                                label='Online'
                            />
                        </TabPanel>
                        <TabPanel>
                            <Field
                                auto
                                isFullWidth
                                name='stockport_customers'
                                component='textarea'
                                label='Stockport Customers'
                            />
                            <Field
                                auto
                                isFullWidth
                                name='aintree_customers'
                                component='textarea'
                                label='Aintree Customers'
                            />
                            <Field
                                auto
                                isFullWidth
                                name='anfield_customers'
                                component='textarea'
                                label='Anfield Customers'
                            />
                            <Field
                                auto
                                isFullWidth
                                name='somercotes_customers'
                                component='textarea'
                                label='Somercotes Customers'
                            />
                            <Field
                                auto
                                isFullWidth
                                name='halifax_customers'
                                component='textarea'
                                label='Halifax Customers'
                            />
                            <Field
                                auto
                                isFullWidth
                                name='sheffield_customers'
                                component='textarea'
                                label='Sheffield Customers'
                            />
                            <Field
                                auto
                                isFullWidth
                                name='middlesbrough_customers'
                                component='textarea'
                                label='Middlesbrough Customers'
                            />
                            <Field
                                auto
                                isFullWidth
                                name='birmingham_customers'
                                component='textarea'
                                label='Birmingham Customers'
                            />
                        </TabPanel>
                        <TabPanel>
                            <Field
                                auto
                                isFullWidth
                                name='suppliers'
                                component='repeater'
                                label='Suppliers'
                                layout='vertical'
                                fieldArray={[
                                    {
                                        name: 'supplier',
                                        component: 'select',
                                        label: 'Supplier',
                                        options: suppliers,
                                        labelKey: 'name',
                                        valueKey: 'id',
                                        isRequired: true,
                                        rules: { required: true }
                                    },
                                    {
                                        name: 'products',
                                        component: 'textarea',
                                        label: 'Products',
                                        isRequired: true,
                                        help: 'Please Enter The Products For This Supplier In The Format: Product Number | Pack UOS | Pack Quantity, (e.g. 585|36|1, 1625|40|1)',
                                        rules: { required: true }
                                    }
                                ]}
                            />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </WrapperView>
        </EditView>
    );
};

export default EditForm;
