import React, { useCallback, useEffect, useState, memo } from "react";
import { useHttp } from "../../hooks";
import { FiTrash, FiPlus } from "react-icons/fi";
import { BiSearch } from "react-icons/bi";
import TableLoader from "../common/TableLoader";
import { useRecoilState, useResetRecoilState } from "recoil";
import { tableAtom } from "../../state/global";
import {
  Box,
  Stack,
  Flex,
  Button,
  useToast,
  Input,
  Text,
} from "@chakra-ui/react";
import LinkButton from "../LinkButton";
import Table from "../Table";

import PopConfirm from "../PopConfirm";
import { useParams } from "react-router-dom";

import { use100vh } from "react-div-100vh";
import Filters from "./Filters";

const MemoTable = memo(
  ({
    setup,
    columns,
    data = null,
    error,
    loading,
    onChangePageSize,
    onChangePage,
    onRowSelect,
    onSortSelect,
    tableOnly,
    ...rest
  }) => {
    return (
      !error && (
        <>
          {!loading ? (
            data.data?.length || tableOnly ? (
              <Table
                accessor={setup.accessor}
                columns={columns}
                data={data}
                loading={loading}
                onChangePageSize={onChangePageSize}
                onChangePage={onChangePage}
                onRowSelect={onRowSelect}
                onSortSelect={onSortSelect}
                size="sm"
                canSelect={setup.canSelect}
                {...rest}
              />
            ) : (
              <Flex w="100%" h="500px" align="center" justify="center">
                <Text fontSize="1.8rem">No results found</Text>
              </Flex>
            )
          ) : (
            <Box p="20px">
              <TableLoader />
            </Box>
          )}
        </>
      )
    );
  }
);

MemoTable.displayName = "MemoTable";

const TableComp = ({
  children,
  setup,
  columns,
  extraMenu = null,
  filters,
  handleRowSelection = null,
  showTopPagination = true,
  hidePagination = false,
  tableOnly = false,
  ...rest
}) => {
  const [tableState, setTableState] = useRecoilState(tableAtom);
  const height = use100vh();
  const { model } = useParams();
  const Http = useHttp();
  const toast = useToast();
  const ExtraComponent = extraMenu;
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    data: [],
  });

  const [selected, setSelected] = useState([]);

  const resetState = useResetRecoilState(tableAtom);

  useEffect(() => {
    resetState();
  }, [model, resetState]);

  /* eslint-disable  */
  const fetchData = () => {
    setLoading(true);

    Http.get(setup.endpoint, { params: tableState.params })
      .then((res) => {
        setData(res.data);
        setLoading(false);
        setError(false);
      })
      .catch(() => {
        toast({
          title: "Error fetching data",
          description: "Please contact support",
          status: "error",
          position: "top",
        });
        setLoading(false);
        setError(true);
      });
  };

  useEffect(() => {
    fetchData();
  }, [
    tableState.params.pagination,
    tableState.params.page,
    tableState.params.sort,
    tableState.params.order,
    tableState.params.filters,
  ]);

  /* eslint-disable  */
  const saveData = (formData) => {
    setLoading(true);

    Http.post(setup.endpoint, formData)
      .then((res) => {
        setData(res.data);
        setLoading(false);
        setError(false);
      })
      .catch(() => {
        toast({
          title: "Error fetching data",
          description: "Please contact support",
          status: "error",
          position: "top",
        });
        setLoading(false);
        setError(true);
      });
  };
  const onChangePage = useCallback(
    (page) => {
      setTableState((old) => ({
        ...old,
        params: { ...old.params, page },
      }));
    },
    [setTableState]
  );

  const handleRowSelect = useCallback(
    (rows) => {
      setSelected(rows.length !== 0 ? rows.map((d) => d.original.id) : []);
      setTableState((old) => ({
        ...old,
        selected: rows.length !== 0 ? rows.map((d) => d.original.id) : [],
        selectedRows: rows.length !== 0 ? rows.map((d) => d.original) : [],
      }));
      if (handleRowSelection) {
        handleRowSelection(rows);
      }
    },
    [setTableState]
  );

  const onSortSelect = useCallback(
    (filter) => {
      if (filter[0]) {
        setTableState((old) => ({
          ...old,
          params: {
            ...old.params,
            sort: filter[0].id,
            order: filter[0].desc ? "desc" : "asc",
          },
        }));
      }
    },
    [setTableState]
  );

  const onDelete = () => {
    setLoading(true);
    return Http.delete(`${setup.endpoint}`, { data: { id: [...selected] } })
      .then(() => {
        fetchData();
      })
      .catch(() => {
        toast({
          title: "Error deleting data",
          message: "Please contact support",
          status: "error",
          position: "top",
          variant: "plain",
        });
        setLoading(false);
      });
  };

  /* eslint-enable */

  const onSearch = useCallback(
    (value) => {
      // setParams((curr) => ({ ...curr, search: value }))
      setTableState((old) => ({
        ...old,
        params: { ...old.params, search: value },
      }));
    },
    [setTableState]
  );

  return (
    <Flex
      height={tableOnly ? "auto" : height}
      overflowX="hidden"
      direction="column"
      maxWidth={{ base: "none", xl: "calc(100vw - 250px)" }}
      justify="flex-start"
    >
      <Flex
        w="100%"
        bg="white"
        h="60px"
        borderBottom="1px"
        align="center"
        px={5}
        borderColor="global.borderColour"
        position="relative"
        zIndex={1000}
      >
        <Text as="h1" fontWeight="semibold" fontSize="18px">
          {setup.title}
        </Text>
      </Flex>
      <Flex w="100%">
        <div>
          {React.cloneElement(children, {
            ...data,
            saveData,
            loading,
          })}
        </div>
      </Flex>
    </Flex>
  );
};

TableComp.displayName = "EditSingle";

export default TableComp;
