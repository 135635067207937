import React from 'react'
import { Box, Alert, Text, AlertIcon } from '@chakra-ui/react'

import { useRecoilValue } from 'recoil'
import { formAtom } from '../../state/form'

const SuccessMessage = () => {
  const { success } = useRecoilValue(formAtom)

  return success ? (
    <Box w='100%' p='5px'>
      <Alert status='success' w='100%' variant='solid' rounded='5px'>
        <AlertIcon />
        <Text fontWeight='semibold'>Entry submitted successfully</Text>
      </Alert>{' '}
    </Box>
  ) : null
}

export default SuccessMessage
