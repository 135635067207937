import React from "react";

import { Badge, Image, Box, IconButton } from "@chakra-ui/react";
import { FiMinus } from "react-icons/fi";

const ProductCard = ({
  product,
  removeItem,
  handleRemoveProduct,
  hideRemoveButton,
}) => (
  <Box
    width='200px'
    height='300px'
    borderWidth='1px'
    borderRadius='lg'
    m='5px'
    position='relative'
    overflow='visible'
  >
    {!hideRemoveButton && (
      <IconButton
        colorScheme='orange'
        aria-label='Call Segun'
        size='sm'
        rounded='full'
        position='absolute'
        right='-10px'
        top='-10px'
        zIndex='15'
        icon={<FiMinus />}
        onClick={() =>
          handleRemoveProduct
            ? handleRemoveProduct(product)
            : removeItem(product)
        }
      />
    )}

    <Box minWidth='198' minHeight='198'>
      <Image
        src={
          "https://api2.parfetts.co.uk/images/products/" + product?.id + ".jpg"
        }
        alt='Image not found'
      />
    </Box>

    <Box p='6'>
      <Box alignItems='baseline'>
        <Badge borderRadius='full' px='2' colorScheme='teal'>
          {product?.brand?.name}
        </Badge>

        <Box
          color='gray.500'
          fontWeight='semibold'
          letterSpacing='wide'
          fontSize='xs'
          textTransform='uppercase'
          ml='2'
        >
          ({product.id}) {product.name}
        </Box>
      </Box>
    </Box>
  </Box>
);

export default ProductCard;
