import React, { useMemo } from "react";
import { AutoFields, EditView } from "@builtbypixel/nucleus";

import setup from "../setup";

const EditForm = () => {
  const fields = useMemo(
    () => [
      {
        group: "General",
        tab: "General",
        fields: [
          {
            name: "name",
            component: "text",
            placeholder: "Name",
            label: "Page Name",
          },
          {
            name: "url",
            component: "text",
            placeholder: "e.g. /products",
            label: "Page URL",
          }
        ],
      },
      {
        group: 'Banner Image',
        tab: 'Banner Image',
        fields: [
          {
            name: 'content.banner_image',
            component: 'file',
            label: 'Banner Image',
            required: true
          }
        ]
      },
      {
        group: 'Top Content',
        tab: 'Top Content',
        fields: [
          {
            name: 'content.title',
            component: 'richtext',
            label: 'Title',
            required: true
          },
          {
            name: 'content.description',
            component: 'richtext',
            label: 'Description',
            required: true
          },
        ]
      },
      {
        group: 'Extensive Range',
        tab: 'Extensive Range',
        fields: [
          {
            name: 'content.extensive_range_title',
            component: 'richtext',
            label: 'Title',
            required: true
          },
          {
            name: 'content.extensive_range_description',
            component: 'richtext',
            label: 'Description',
            required: true
          },
          {
            name: 'content.extensive_range_image',
            component: 'file',
            label: 'Banner Image',
            required: true
          }
        ]
      },
      {
        group: 'Shop Range',
        tab: 'Shop Range',
        fields: [
          {
            name: 'content.shop_range_title',
            component: 'text',
            label: 'Title'
          },
          {
            name: 'cotent.shop_range_link',
            component: 'text',
            label: 'Link'
          }
        ]
      },
      {
        group: "SEO",
        tab: "SEO",
        fields: [
          {
            name: "seo_title",
            component: "text",
            label: "SEO title"
          },
          {
            name: "seo_description",
            component: "textarea",
            label: "SEO description"
          }
        ]
      }
    ],
    []
  );

  return (
    <EditView setup={setup} isFullWidth={false}>
      <AutoFields fields={fields} />
    </EditView>
  );
};

export default EditForm;
