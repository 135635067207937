import React, { useState } from "react";
import { EditView, Field, debounce } from "@builtbypixel/nucleus";
import WrapperView from "../../components/CustomEditWrapper";

import setup from "./setup";
import { useHttp } from "../../Nucleus";

const EditForm = ({ match, ...props }) => {
  const [defaultProducts, setDefaultProducts] = useState();

  const Http = useHttp();

  const handleGetProducts = debounce((search, callback) => {
    return Http.post(
      `/promoted-products?pagination=9999&search=${search}`
    ).then((res) => {
      callback(res.data.data);
      setDefaultProducts(res.data.data);
    });
  }, 1000);

  const isEdit = match.params.type === "edit";

  return (
    <EditView setup={setup} isFullWidth={false}>
      <WrapperView>
        <Field name="is_active" component="switch" label="Active" />
        <Field
          name="title"
          component="text"
          placeholder="title"
          label="Title"
          isRequired
        />
        <Field
          name="slug"
          component="text"
          placeholder="slug"
          label="Link"
          isRequired
        />
        <Field
          name="start_date"
          component="date"
          placeholder="Start Date"
          label="Start Date"
          isRequired
        />
        <Field
          name="end_date"
          component="date"
          placeholder="End Date"
          label="End Date"
          isRequired
        />
        <Field
          name="video"
          component="file"
          label="Video"
          isRequired
          max={1}
          disabled={isEdit}
          isCloudinary
        />
        <Field
          name="products"
          component="select"
          placeholder="Select Products"
          isMulti={true}
          loadOptions={handleGetProducts}
          isRequired={true}
          label="Products"
          labelKey="name"
          valueKey="id"
          isAsync="true"
          defaultOptions={defaultProducts ? defaultProducts : null}
          selectValue="id"
          closeOnSelect={false}
          closeMenuOnSelect={false}
        />
      </WrapperView>
    </EditView>
  );
};

export default EditForm;
