import React from "react";
import { EditView, Field } from "@builtbypixel/nucleus";
import setup from "./setup";
import ProductsContainer from "../../components/ProductsContainer";
import WrapperView from "../../components/CustomEditWrapper";

const EditForm = () => {
  return (
    <EditView setup={setup} isFullWidth={false}>
      <WrapperView>
        {/* Acutal edit name form bit */}
        <Field
          auto
          isFullWidth
          name='name'
          component='text'
          label='Name'
          isRequired
          rules={{ required: true }}
        />

        {/* custom product view*/}
        <ProductsContainer />
      </WrapperView>
    </EditView>
  );
};

export default EditForm;
