const useTableStyles = (props) => {
    return {
        h: 'auto',

        zIndex: 0,
        position: 'relative',
        w: '100%',
    };
};

const cellSizes = {
    lg: {
        px: '10px',
        py: '15px',
        fontSize: 'lg',
    },
    md: {
        px: '10px',
        py: '8px',
        fontSize: 'md',
    },
    sm: {
        px: '10px',
        py: '6px',
    },
};

const tableRowStyles = (props) => {
    return {
        fontWeight: props.isSelected && 'semibold',
        bg: props.isSelected ? 'tables.rowSelectedBg' : 'transparent',
        transition: 'all 0.3s ease',
    };
};

const tableCellStyles = (props) => {
    return {
        bg: 'transparent',
        ...cellSizes[props.size],
        textOverflow: 'ellipsis',
        boxSizing: 'border-box',
        position: 'relative',
        overflowX: 'hidden',
        whiteSpace: 'nowrap',
        minWidth: 150,
        minHeight: '35px',
        height: '35px',
        display: 'table-cell',
    };
};

const headCellSizes = {
    lg: {
        px: '10px',
        py: '15px',
        fontSize: 'lg',
    },
    md: {
        px: '10px',
        py: '6px',
        fontSize: 'md',
    },
    sm: {
        px: '10px',
        py: '8px',
        fontSize: 'sm',
    },
};

const tableHeadCellStyles = (props) => {
    return {
        color: props.isSorted ? 'secondary' : 'primary',
        fontWeight: 'semibold',

        ...headCellSizes[props.size],
        fontSize: 'md',
        position: 'sticky',
        top: '0px',
        display: 'table-cell',

        minWidth: 150,
    };
};

export { useTableStyles, tableCellStyles, tableHeadCellStyles, tableRowStyles };
