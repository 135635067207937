import React, { useMemo } from 'react';
import { LinkButton, ListWrapper } from '@builtbypixel/nucleus';

import { IconButton } from '@chakra-ui/react';
import { FiEdit } from 'react-icons/fi';
import setup from './setup';
import { Button, Image } from '@chakra-ui/react';
// Extra Menu appears next to the create button
const extraMenu = () => {
    return null;
};

const columns = [
    {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ row }) => (
        <LinkButton to={`${setup.model}/edit/${row.original[setup.accessor]}`}>
            <Button variant="link" variantColor="secondary" rounded="md">
            {row.original.name}
            </Button>
        </LinkButton>
        ),
    },
    {
        Header: 'Image',
        accessor: 'image',
        Cell: ({ row }) => (
            <Image height="40px" src={row.original.image?.[0].url} />
        ),
    },
    {
        Header: 'Address',
        accessor: 'address'
    },
    {
        Header: 'Start Date',
        accessor: 'start_date'
    },
    {
        Header: 'End Date',
        accessor: 'end_date'
    },
    {
        Header: 'Active',
        accessor: 'active',
        Cell: ({ row }) => (
            row?.original?.active === true ? 'Yes' : 'No'
        ),
    },
    {
        Header: 'Online',
        accessor: 'online',
        Cell: ({ row }) => (
            row?.original?.online === true ? 'Yes' : 'No'
        ),
    }
];

const List = () => {
    
    return (
        <ListWrapper
            setup={setup}
            columns={columns}
            extraMenu={extraMenu}
            handleRowSelection={(rows) => console.log(rows)}
        />
    );
};

export default List;
