import React from "react";
import { LinkButton, ListWrapper } from "@builtbypixel/nucleus";
import { Button, Image, Text } from "@chakra-ui/react";
import setup from "./setup";

// Extra Menu appears next to the create button.  You can call any component you wish.
// const extraMenu = () => {
//    return (
//       <Flex>
//          <Flex>fdgdsgfddf</Flex>
//       </Flex>
//    )
// }

const extraMenu = () => null;

const List = () => {
  const columns = [
    {
      Header: "File Name",
      accessor: "filename",
    },
    {
      Header: "Message",
      accessor: "message",
    },
    {
      Header: "Status",
      accessor: "error",
      Cell: ({value}) => value && "error"
    },
    {
      Header: "Removed From Basket",
      accessor: "removed",
      Cell: ({value}) => value ? "Yes" : "No"
    }
  ];

  return <ListWrapper setup={setup} columns={columns} extraMenu={extraMenu} />;
};

export default List;
