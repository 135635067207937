import React, { useMemo, useEffect, useState } from "react";
import { AutoFields, EditView, Field } from "@builtbypixel/nucleus";
import { useHttp } from "../../Nucleus/hooks";
import Schemes from "./schemes";

import setup from "./setup";

const EditForm = () => {
  const Http = useHttp();
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    Http.get("/categories/top-level").then((res) => {
      setCategories(res.data);
    });
  }, []);
  const fields = useMemo(
    () => [
      {
        group: "",
        fields: [
          {
            name: "name",
            component: "text",
            placeholder: "name",
            label: "Name",
          },
          {
            name: "limit",
            component: "text",
            label: "Limit",
          },
          {
            name: "from_date",
            component: "date",
            label: "Start Date",
          },
          {
            name: "to_date",
            component: "date",
            label: "End Date",
          },
          {
            name: "categories",
            component: "select",
            label: "Categories",
            options: categories,
            labelKey: "name",
            valueKey: "id",
            selectValue: "value",
            isMulti: true,
            help: "Select which categories (if any) you want to apply the limit to",
          },
          // {
          //   name: "schemes",
          //   component: "repeater",
          //   label: "Branches/Schemas",
          //   layout: "vertical",
          //   help: "Select which branches/schemas (if any) you want to apply the limit to",
          //   fieldArray: [
          //     {
          //       name: "",
          //       component: <p>hi</p>,
          //     },
          //   ],
          // },
          {
            name: "schemes",
            component: Schemes,
          },
        ],
      },
    ],
    [categories]
  );

  return (
    <EditView setup={setup} isFullWidth={false}>
      <AutoFields fields={fields} />
    </EditView>
  );
};

export default EditForm;

// [Violation] Added non-passive event listener to a scroll-blocking 'touchstart' event. Consider marking event handler as 'passive' to make the page more responsive. See https://www.chromestatus.com/feature/5745543795965952
