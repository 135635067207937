import React from "react";
import {
  Modal,
  ModalContent,
  IconButton,
  ModalHeader,
  useDisclosure,
  Tooltip
} from "@chakra-ui/react";
import { FiRepeat } from "react-icons/fi";
import SiteSelector from "./SiteSelector";
import { globalAtom } from "../../state/global";
import { useRecoilValue } from "recoil";

const SiteSwitchModal = () => {
  const globalState = useRecoilValue(globalAtom);
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <React.Fragment>
      <Tooltip label="switch site">
      <IconButton
        rounded="full"
        size="lg"
        variant="ghost"
        onClick={() => onOpen()}
      >
        <FiRepeat />
      </IconButton>
      </Tooltip>
      <Modal onClose={onClose} isOpen={isOpen}>
        <ModalContent>
          <ModalHeader>Select a site</ModalHeader>
          <SiteSelector sites={globalState.config?.siteSwitcher.sites} />
        </ModalContent>
      </Modal>
    </React.Fragment>
  );
};

export default SiteSwitchModal;
