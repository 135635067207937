const colors = {
   primary: '#18191a',
   secondary: '#3677f8',
   secondaryLight: 'rgba(53, 120, 248, 0.12)',
   global: {
      bg: '#f0f2f5',
      elementBgLight: '#ffffff',
      text: 'rgba(0,0,0,0.9)',
      borderColour: 'rgba(0,0,0,0.1)'
   },
   inputs: {
      borderColour: '#CBD5E0',
      placeholderColour: '#A0AEC0'
   },
   grayBg: '#edf2f7',
   richText: {
      editorBg: '#ffffff'
   },
   success: '#27ad60'
}

export default colors
