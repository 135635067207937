import React, { useEffect, useState } from 'react';
import { useHttp } from '../../Nucleus/hooks/useHttp';
import { useParams } from 'react-router-dom';
import axios from "axios";
import {
    Box, Text, Flex, Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Button
} from '@chakra-ui/react';
import { EditView, ListWrapper } from '@builtbypixel/nucleus';
import setup from './setup';
import WrapperView from "../../components/CustomEditWrapper";

const EditForm = () => {

    const { id } = useParams();
    const Http = useHttp()
    const [impression, setImpression] = useState(null);
    const [selectedTable, setSelectedTable] = useState('revenue');
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        if (id) {
            Http.get(`/app-event-impressions/${id}`).then(
                (res) => {
                    setImpression(res?.data?.data)
                }
            )
        }
    }, [id]);

    const exportData = () => {
        setLoading(true)

        axios({
            url: `/app-event-export/${id}`,
            method: 'GET',
            responseType: 'blob',
        }).then(
            (response) => {
                const href = URL.createObjectURL(response.data);

                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', `${impression?.slug}.xlsx`); //or any other extension
                document.body.appendChild(link);
                link.click();

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            }
        )

        setLoading(false)
    }

    return (
        <EditView setup={setup}>
            <WrapperView>
                <Flex mb='3' p='1' flexDirection="row" justifyContent="space-between" w='100%' align='center'>
                    <Text fontSize='16px' fontWeight="bold">{impression?.name}</Text>
                    <Button isLoading={loading} disabled={loading} _hover={{ bg: '#27ad60' }} bg='#27ad60' color='white' onClick={() => exportData()}>Export (Excel)</Button>
                </Flex>
                <Flex justifyContent="space-between" w='100%' mb='3'>
                    <Button _hover={{ bg: '#27ad60', color: 'white' }} bg={selectedTable === 'revenue' ? '#27ad60' : '#f4f5f8'} color={selectedTable === 'revenue' && 'white'} onClick={() => setSelectedTable('revenue')} w='100%' h='auto' mt='10px' mr='10px' p='1' borderRadius='5px'>
                        <Flex flexDirection="column" p='5'>
                            <Text>Revenue</Text>
                            <Text fontSize="24px" fontWeight='bold' mt='5px'>{`£${impression?.total_revenue}`}</Text>
                        </Flex>
                    </Button>
                    <Button _hover={{ bg: '#27ad60', color: 'white' }} bg={selectedTable === 'products' ? '#27ad60' : '#f4f5f8'} color={selectedTable === 'products' && 'white'} onClick={() => setSelectedTable('products')} w='100%' h='auto' mt='10px' mr='10px' p='1' borderRadius='5px'>
                        <Flex flexDirection="column" p='5'>
                            <Text>Total Products</Text>
                            <Text fontSize="24px" fontWeight='bold' mt='5px'>{impression?.total_products}</Text>
                        </Flex>
                    </Button>
                    <Button _hover={{ bg: '#27ad60', color: 'white' }} bg={selectedTable === 'units' ? '#27ad60' : '#f4f5f8'} color={selectedTable === 'units' && 'white'} onClick={() => setSelectedTable('units')} w='100%' h='auto' mt='10px' mr='10px' p='1' borderRadius='5px'>
                        <Flex flexDirection="column" p='5'>
                            <Text>Total Units</Text>
                            <Text fontSize="24px" fontWeight='bold' mt='5px'>{impression?.total_units}</Text>
                        </Flex>
                    </Button>
                    <Button bg='#f4f5f8' color='black' w='100%' h='auto' mt='10px' p='1' borderRadius='5px'>
                        <Flex flexDirection="column" p='5'>
                            <Text>Total Removed Revenue</Text>
                            <Text fontSize="24px" fontWeight='bold' mt='5px'>{`£${new Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(impression?.total_removed)}`}</Text>
                        </Flex>
                    </Button>
                </Flex>

                <Box display={selectedTable === 'revenue' ? 'block' : 'none'} p='1' borderRadius='5px'>
                    <ListWrapper
                        setup={
                            {
                                title: 'Total Revenue',
                                singular: 'Revenue',
                                model: 'revenue',
                                canDelete: false,
                                canAddNew: false,
                                canSearch: true,
                                endpoint: `/app-event-revenues/${id}`,
                                accessor: 'id'
                            }
                        }
                        columns={[
                            {
                                Header: 'Supplier',
                                accessor: 'supplier',
                            },
                            {
                                Header: 'Units',
                                accessor: 'units',
                            },
                            {
                                Header: 'Revenue',
                                accessor: 'revenue',
                                Cell: ({ row }) => (
                                    <Text>{`£${new Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(row.original.revenue)}`}</Text>
                                ),
                            }
                        ]}
                        extraMenu={null}
                        handleRowSelection={(rows) => console.log(rows)}
                    />
                </Box>

                <Box display={(selectedTable === 'products' || selectedTable === 'units') ? 'block' : 'none'} p='5' borderRadius='5px'>
                    <ListWrapper
                        setup={
                            {
                                title: 'Products',
                                singular: 'Product',
                                model: 'product',
                                canDelete: false,
                                canAddNew: false,
                                canSearch: true,
                                endpoint: `/app-event-products/${id}`,
                                accessor: 'id'
                            }
                        }
                        columns={[
                            {
                                Header: 'Product ID',
                                accessor: 'id',
                            },
                            {
                                Header: 'Product',
                                accessor: 'product',
                            },
                            {
                                Header: 'Supplier',
                                accessor: 'supplier',
                            },
                            {
                                Header: 'Price',
                                accessor: 'price',
                                Cell: ({ row }) => (
                                    <Text>{`£${new Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(row.original.price)}`}</Text>
                                ),
                            },
                            {
                                Header: 'Units',
                                accessor: 'quantity',
                            },
                            {
                                Header: 'Total Revenue',
                                accessor: 'revenue',
                                Cell: ({ row }) => (
                                    <Text>{`£${new Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(row.original.revenue)}`}</Text>
                                ),
                            }
                        ]}
                        extraMenu={null}
                        handleRowSelection={(rows) => console.log(rows)}
                    />
                </Box>
            </WrapperView>
        </EditView>
    );
};

export default EditForm;
