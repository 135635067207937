import React from "react";
import Cloudinary from "./Cloudinary";
import Standard from "./Standard";

const FileUpload = ({ isCloudinary = false, ...props }) => {
  return process.env.REACT_APP_UPLOAD_METHOD === "cloudinary" &&
    isCloudinary ? (
    <Cloudinary {...props} />
  ) : (
    <Standard {...props} />
  );
};

export default FileUpload;
