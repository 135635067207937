import { Flex, Box, Text, Input, Button, useToast } from "@chakra-ui/react";
import { useState, useEffect, useRef, useContext } from "react";
import { useHttp } from "../../Nucleus/hooks";
import CustomPage from "../../Nucleus/components/common/CustomPage";
const ProductImage = (props) => {
  const inputRef = useRef(null);
  const Http = useHttp();
  const toast = useToast();

  const handleFile = (files) => {
    console.log("in handleFile");

    for (var i = 0; i < files.length; i++) {
      const formData = new FormData();
      formData.append("file", files[i]);
      Http.post("/products/image-upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          toast({
            title: "Success",
            description: "Image successfully uploaded",
            status: "success",
            duration: 5000,
            isClosable: false,
          });
        })
        .catch((err) => {
          toast({
            title: "Error",
            description: `There was a problem with uploading the image. Error: ${err.message}`,
            duration: null,
            isClosable: true,
          });
        });
    }
  };

  return (
    <CustomPage>
      <Box borderLeft="1px solid #eeeeee" p="20px">
        <Input
          type="file"
          ref={inputRef}
          display="none"
          multiple
          onChange={(e) => handleFile(e.target.files)}
        />
        <Box width="100%">
          <Text fontWeight="500" fontSize="30px">
            Product Image Upload
          </Text>
          <Text mb="30px">
            Only upload .jpg images that are 600 x 600 with the product id as
            the name of the file, i.e. “270741.jpg”. <br />
            To upload thumbnails suffix the image name with thumbnail and upload
            an image that is 40 x 40, for example “146637_thumbnail.jpg”.
          </Text>
          <Flex>
            <Input mr="8px" onClick={() => inputRef.current.click()} />
            <Button onClick={() => inputRef.current.click()}>Browse</Button>
          </Flex>
        </Box>
      </Box>
    </CustomPage>
  );
};

export default ProductImage;
