import React from "react";
import { LinkButton, ListWrapper } from "@builtbypixel/nucleus";
import { Button, Text, Flex, Icon } from "@chakra-ui/react";
import setup from "./setup";
import { FaCheck } from "react-icons/fa";

// Extra Menu appears next to the create button.  You can call any component you wish.
// const extraMenu = () => {
//    return (
//       <Flex>
//          <Flex>fdgdsgfddf</Flex>
//       </Flex>
//    )
// }

const extraMenu = () => null;

const List = () => {
  const columns = [
    {
      Header: "Name",
      accessor: "name",
      id: "edit",
      Cell: ({ row }) => (
        <LinkButton to={`${setup.model}/edit/${row.original.id}`}>
          <Button variant="link" variantColor="secondary" rounded="md">
            {row.original.name}
          </Button>
        </LinkButton>
      ),
    },
    {
      Header: "Start Date",
      accessor: "start_date",
    },
    {
      Header: "End Date",
      accessor: "end_date",
    },
    {
      Header: "Products",
      accessor: "product_count",
    },
    {
      Header: "Visible on site",
      Cell: ({ row }) => {
        if (row.original.is_active) {
          return (
            <Flex>
              <Icon color="green" as={FaCheck} />
            </Flex>
          );
        } else return null;
      },
    },
  ];

  return <ListWrapper setup={setup} columns={columns} extraMenu={extraMenu} />;
};

export default List;
