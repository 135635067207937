import {
  Flex,
  Box,
  Text,
  Input,
  Button,
  Image,
  Spinner,
  useToast
} from "@chakra-ui/react";
import React, { useState, useEffect, useRef } from "react";
import { useHttp } from "../../Nucleus/hooks";
import CustomPage from "../../Nucleus/components/common/CustomPage";

const OwnBanner = () => {
  const toast = useToast()
  const [upload, setUpload] = useState([]);
  const [link, setLink] = useState("");
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true)
  const inputRef = useRef(null);
  const Http = useHttp();
  useEffect(() => {
    Http.get("/own-label-banner").then((res) => {
      setData(res.data);
      setLink(res.data.data[0]?.link);
      setLoading(false)
    });
  }, []);

  const submit = () => {
    Http.post("/own-label-banner", { upload, link }).then((res) => {
      toast({
        title: "Success",
        description: "The own label banner has been successfully updated",
        status: "success",
        duration: 5000,
        isClosable: false
      })
    }).catch((err) => {
      toast({
        title: "Error",
        description: `There was a problem with your request. Error: ${err.message}`,
        status: "error",
        duration: null,
        isClosable: true
      })
    })
  };

  const handleFile = (rawFile) => {
    const formData = new FormData();
    formData.append("file", rawFile);
    Http.post("/products/image-upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).then((res) => {
      setUpload(res.data);
    });
  };


  if (loading) return (
    <Box mx="auto" my="auto">
      <Spinner size="xl" />
    </Box>
  )

  return (
    <CustomPage>
        <Box borderLeft="1px solid #eeeeee" p="20px" bg="white" width="100%">
          <Flex borderBottom="solid 1px #eeeeee" mb="20px">
            <Text fontSize="32px">Own-Label Banner</Text>
            <Button ml="auto" bg="#229652" onClick={() => submit()} color="white">
              Save own Label Banner
            </Button>
          </Flex>
            <Input
              type="file"
              ref={inputRef}
              display="none"
              onChange={(e) => handleFile(e.target.files[0])}
            />
          <Box width="100%">
            <Flex>
              <Input
                value={upload.original_filename || data.data[0]?.original_filename}
                mr="8px"
                onClick={() => inputRef.current.click()}
              />
              <Button onClick={() => inputRef.current.click()}>Browse</Button>
            </Flex>
            <Box>
              <Text mt="20px" mb="5px" fontSize="22px">
                Preview
              </Text>
              {loading ? <Spinner/> : (
                <Flex bg="#d9f4fd" borderRadius="10px" p="15px" width="50%">
                  <Image src={upload.url || data.data[0]?.url} objectFit="cover" width="100%" />
                </Flex>
              )}
            </Box>
            <Box mt={4}>
              <Text
                fontSize="11px"
                fontWeight="semibold"
                color="gray.600"
                mb="3px"
              >
                Banner Link
              </Text>
              <Input
                placeholder="Banner Link"
                onChange={(e) => setLink(e.target.value)}
                value={link}
              />
            </Box>
          </Box>
        </Box>
    </CustomPage>
  );
};

export default OwnBanner;
