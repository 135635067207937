const modelName = 'App Event Trolleys'
const modelNameSingular = 'App Event Trolley'
const endpoint = '/app-event-trolleys'

const setup = {
  title: modelName,
  singular: modelNameSingular,
  model: modelName.replace(/\s+/g, '-').toLowerCase(),
  canDelete: false,
  canAddNew: false,
  canSearch: true,
  endpoint,
  accessor: 'id'
}

export default setup
