import React, { useMemo } from "react";
import { EditView, useHttp, debounce, Field } from "@builtbypixel/nucleus";
import ProductsContainer from "../../components/ProductsContainer";
import WrapperView from "../../components/CustomEditWrapper";
import setup from "./setup";

const EditForm = () => {

  const Http = useHttp();

  const handleSearchProducts = debounce((search, callback) => {
    return Http.get(`/products?pagination=9999&search=${search}`).then(
      (res) => {
        callback(res.data.data);
      }
    );
  }, 1000);

  return (
    <EditView setup={setup} isFullWidth={false}>
      <WrapperView>
        {/* Acutal edit name form bit */}
        <Field
          auto
          isFullWidth
          name='name'
          component='text'
          label='Name'
          isRequired
          rules={{ required: true }}
        />

        <Field
          auto
          isFullWidth
          name='date_from'
          component='date'
          label='Start Date'
          isRequired
          rules={{ required: true }}
        />

        <Field
          auto
          isFullWidth
          name='date_to'
          component='date'
          label='End Date'
          isRequired
          rules={{ required: true }}
        />

        <Field
          auto
          isFullWidth
          name='products'
          component='textarea'
          label='Products'
          isRequired
          rules={{ required: true }}
          help="Please enter the product codes separated by a comma. The order in which they are added here will be the order they are displayed on the website."
        />
      </WrapperView>
    </EditView>
  );
};

export default EditForm;
