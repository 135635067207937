import React from "react";
import { EditView, Field } from "@builtbypixel/nucleus";
import WrapperView from "../../components/CustomEditWrapper";
import ProductsContainer from "../../components/ProductsContainer";

import setup from "./setup";

const EditForm = () => {
  return (
    <EditView setup={setup} isFullWidth={false}>
      <WrapperView>
        <ProductsContainer />
      </WrapperView>
    </EditView>
  );
};

export default EditForm;
