import {
    Flex,
    Box,
    Text,
    Input,
    Button,
    Image,
    Spinner,
    useToast,
    FormLabel,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    IconButton,
  } from "@chakra-ui/react";
  import { useState, useEffect, useRef} from "react";
  import { FiPlus, FiTrash } from "react-icons/fi";
  import { useHttp } from "../../Nucleus/hooks";
  import CustomPage from "../../Nucleus/components/common/CustomPage";
  import AsyncSelect from 'react-select/async';
import PopConfirm from "../../Nucleus/components/PopConfirm";

  const HomePageBrands = (props) => {
    const toast = useToast()
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true)
    const Http = useHttp();

    useEffect(() => {
      Http.get("/homepage-brands").then((res) => {
        setData(res.data.data.brands);
        setLoading(false)
      });
    }, []);

    const handleGetBrands = (option) => {
        return Http.get(`all-brands?search=${option}`).then((response) => {
        return response.data.data
        });
    }
  
    const submit = () => {
      Http.post("/homepage-brands", {
        brands: data
      }).then((res) => {
        toast({
          title: "Success",
          description: "Successfully updated",
          status: "success",
          duration: 5000,
          isClosable: false
        })
      }).catch((err) => {
        toast({
          title: "Error",
          description: `There was a problem with your request. Error: ${err.message}`,
          status: "error",
          duration: null,
          isClosable: true
        })
      })
    };

    useEffect(() => {
        console.log(data, 'show data');
    }, [data]);

    const addNew = () => {
        let newBrand = {
            brand: null,
            order: null,
        }

        setData([...data, newBrand ]);
    }

    const onChange = (e, item, index) => {
        let updateData = [...data];
        updateData[index].brand = e;
        setData(updateData);
    }

    const onChangeNumber = (e, item, index) => {
        let updateData = [...data];
        updateData[index].order = parseInt(e);
        setData(updateData);
    } 

    const removeBrand = (index) => {
        let remove = [...data];
        remove.splice(index, 1);
        setData(remove);
    }
  
    if (loading) return (
      <Box mx="auto" my="auto">
        <Spinner size="xl" />
      </Box>
    )
  
    return (
      <CustomPage>
          <Box borderLeft="1px solid #eeeeee" p="20px" bg="white" width="100%">
            <Flex borderBottom="solid 1px #eeeeee" mb="20px">
              <Text fontSize="32px">Homepage Brands</Text>
              <Button ml="auto" bg="#229652" onClick={() => submit()} color="white">
                Save Homepage Brands
              </Button>
            </Flex>
            <Box width="100%">
                {data.map((item, index) => {
                    return (
                        <Box key={item?.id} style={{ padding: 10, borderWidth: 1, borderRadius: 5, marginBottom: 20 }}>
                            <Flex justify="space-between" align="center">
                                {item?.brand !== null
                                    ? <Text style={{ fontSize: 18, fontWeight: '600', marginBottom: 10 }}>Brand - {item?.brand?.name}</Text>
                                    : <Text style={{ fontSize: 18, fontWeight: '600', marginBottom: 10 }}>New Brand</Text>
                                }
                                <PopConfirm
                                    onConfirm={() => removeBrand(index)}
                                    title={`Are you sure you want to remove ${item?.brand?.name}?`}
                                >
                                    <IconButton
                                        variant='link'
                                        fontSize={18}
                                        ml='5px'
                                        icon={<FiTrash />}
                                    />
                                </PopConfirm>
                            </Flex>
                            <Box>
                                <FormLabel>Select Brand</FormLabel>
                                <AsyncSelect
                                    cacheOptions
                                    loadOptions={handleGetBrands}
                                    defaultValue={item.brand}
                                    onChange={(e) => onChange(e, item, index)}
                                    getOptionLabel={(options) => options['name']}
                                    getOptionValue={(options) => options['id']}
                                />
                            </Box>
                            <Box style={{ marginTop: 20 }}>
                                <FormLabel>Order</FormLabel>
                                <NumberInput 
                                    defaultValue={item?.order}
                                    onChange={(e) => onChangeNumber(e, item, index)}
                                >
                                    <NumberInputField />
                                    <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                    </NumberInputStepper>
                                </NumberInput>
                            </Box>
                        </Box>
                    );
                })}
                <Button 
                    ml="auto" 
                    bg="#000" 
                    onClick={() => addNew()} 
                    color="white"
                    leftIcon={<FiPlus />}
                >
                    Add New Brand
                </Button>
            </Box>
          </Box>
      </CustomPage>
    );
  };
  
  export default HomePageBrands;
  