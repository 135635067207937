import React, { useEffect, useMemo, useState } from "react";
import { AutoFields, EditView, useHttp, debounce } from "@builtbypixel/nucleus";
import setup from "./setup";
import { Box } from "@chakra-ui/react";

const EditForm = () => {
  const Http = useHttp();

  const handleSearchProducts = debounce((search, callback) => {
    return Http.get(
      `/categories/top-level?pagination=9999&search=${search}`
    ).then((res) => {
      callback(res.data);
    });
  }, 1000);

  const fields = useMemo(
    () => [
      {
        group: "Ab Banner Testing Details",

        fields: [
          {
            name: "name",
            component: "text",
            rules: { required: true },
            label: "Name",
          },
          {
            name: "categories",
            component: "repeater",
            layout: "vertical",
            fieldArray: [
              {
                name: "category",
                component: "select",
                loadOptions: handleSearchProducts,
                rules: { required: true },
                label: "Category Banner",
                labelKey: "name",
                valueKey: "id",
                isAsync: true,
                selectValue: "id",
                isHorizontal: true,
                defaultOptions: true,
              },
              {
                name: "image_a",
                label: "Image A",
                component: "file",
                max: 1,
                rules: { required: true },
              },
              {
                name: "image_b",
                label: "Image B",
                component: "file",
                max: 1,
                rules: { required: true },
              },
            ],
          },
        ],
      },
    ],
    []
  );

  return (
    <EditView setup={setup} isFullWidth>
      <Box bg="white" w="100%" display="block" p={5}>
        <AutoFields fields={fields} isFullWidth />
      </Box>
    </EditView>
  );
};

export default EditForm;
