import React, { useMemo, useState, useEffect, useCallback } from 'react';

import { useHttp } from '../../../Nucleus/hooks';
import debounce from 'lodash.debounce';
import {
  useFormContext,
  useFieldArray,
  Controller,
  useWatch,
} from 'react-hook-form';

import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Input,
  Text,
} from '@chakra-ui/react';

import AsyncSelect from 'react-select/async';
import { Field } from '../../../Nucleus/components/Form';

const OwnLabelRange = () => {
  const Http = useHttp();
  const [brands, setBrands] = useState([]);
  const [brandLoading, setBrandLoading] = useState(false);
  const brandProducts = useWatch({ name: 'brand_products' });

  useEffect(() => {
    setBrandLoading(true);
    Http.get('/brands?pagination=10')
      .then((res) => {
        setBrandLoading(false);
        setBrands(
          res?.data?.data.map((item) => {
            return {
              ...item,
              name: item.name,
              label: item.name,
              value: item.id,
            };
          })
        );
      })
      .catch((err) => {
        setBrandLoading(false);
        console.log(err);
      });
  }, []);

  const brandOptions = useCallback(
    debounce((inputText, callback) => {
      changeBrand(inputText).then((options) => callback(options));
    }, 3000),
    []
  );

  const changeBrand = (search) => {
    setBrandLoading(true);
    return Http.get(`/brands?search=${search}`)
      .then((res) => {
        setBrandLoading(false);

        return res?.data?.data.map((item) => {
          return {
            ...item,
            name: item.name,
            label: item.name,
            value: item.id,
          };
        });
      })
      .catch((err) => {
        setBrandLoading(false);

        console.log(err);
      });
  };

  const { register, control, handleSubmit, reset, setValue, getValues } =
    useFormContext({});

  const { fields, append, prepend, remove, swap, move, insert, replace } =
    useFieldArray({
      control,
      name: 'brand_products',
    });

  return (
    <Flex bg='white' direction='column' w='100%' h='100%' m='1em' p='1.5em'>
      <Field name='name' component='text' label='name' disabled />
      {fields.map((item, index) => {
        return (
          <Box p='3em' w='60%' m='1em' border='1px solid black'>
            <Grid key={item.id} templateColumns='80% 4em' gap={2}>
              <GridItem>
                <AsyncSelect
                  name={`brand_products.[${index}].brand`}
                  placeholder='Select Brand'
                  loadOptions={brandOptions}
                  isAsync={true}
                  defaultOptions={brands}
                  onChange={(value) => {
                    setValue(`brand_products.[${index}].brand`, value);
                  }}
                  isLoading={brandLoading}
                  formatOptionLabel={(brand) => (
                    <Box>
                      <Text>{brand.name}</Text>
                    </Box>
                  )}
                  value={brandProducts?.[index]?.brand}
                  menuPlacement={'auto'}
                />
              </GridItem>
              <GridItem>
                <Button type='button' onClick={() => remove(index)}>
                  Delete
                </Button>
              </GridItem>
              {brandProducts?.[index]?.brand?.id && (
                <GridItem>
                  <ProductsDropdown
                    index={index}
                    selectedBrand={brandProducts?.[index]?.brand}
                    value={brandProducts?.[index]?.products}
                  />
                </GridItem>
              )}
            </Grid>
          </Box>
        );
      })}

      <section>
        <Button
          type='button'
          onClick={() => {
            append({ brand: '', products: [] });
          }}
        >
          Add Item
        </Button>
        {/* <Button
          type='button'
          onClick={() =>
            prepend({
              firstName: 'prependFirstName',
              lastName: 'prependLastName',
            })
          }
        >
          {' '}
          prepend
        </Button>
        <Button
          type='button'
          onClick={() =>
            insert(2, {
              firstName: 'insertFirstName',
              lastName: 'insertLastName',
            })
          }
        >
          {' '}
          insert at
        </Button>

        <Button type='button' onClick={() => swap(1, 2)}>
          {' '}
          swap
        </Button>

        <Button type='button' onClick={() => move(1, 2)}>
          {' '}
          move
        </Button>

        <Button
          type='button'
          onClick={() =>
            replace([
              {
                firstName: 'test1',
                lastName: 'test1',
              },
              {
                firstName: 'test2',
                lastName: 'test2',
              },
            ])
          }
        >
          {' '}
          replace
        </Button>

        <Button type='button' onClick={() => remove(1)}>
          {' '}
          remove at
        </Button>

        <Button
          type='button'
          onClick={() =>
            reset({
              test: [{ firstName: 'Bill', lastName: 'Luo' }],
            })
          }
        >
          {' '}
          reset
        </Button> */}
      </section>
    </Flex>
  );
};

export default OwnLabelRange;

const ProductsDropdown = ({ selectedBrand, index, value }) => {
  const Http = useHttp();

  const [products, setProducts] = useState([]);
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [productsLoading, setProductsLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [menuWasClosed, setMenuWasClosed] = useState(false);

  const { register, control, handleSubmit, reset, setValue } = useFormContext(
    {}
  );

  useEffect(() => {
    if (!inputValue) setDefaultOptions(products);
  }, [inputValue]);

  useEffect(() => {
    setProductsLoading(true);
    Http.get(`products-by-brand/${selectedBrand?.id}`)
      .then((res) => {
        setProductsLoading(false);
        setProducts(
          res?.data?.map((item) => {
            return {
              ...item,
              name: item.name,
              label: item.name,
              value: item.id,
            };
          })
        );
        setDefaultOptions(
          res?.data?.map((item) => {
            return {
              ...item,
              name: item.name,
              label: item.name,
              value: item.id,
            };
          })
        );
      })
      .catch((err) => {
        setProductsLoading(false);
        console.log(err);
      });
  }, [selectedBrand]);

  const productOptions = useCallback(
    debounce((inputText, callback) => {
      setInputValue(inputText);
      changeProduct(inputText).then((options) => callback(options));
    }, 3000),
    []
  );

  const changeProduct = (search) => {
    setProductsLoading(true);
    return Http.get(`/products-by-brand/${selectedBrand?.id}?search=${search}`)
      .then((res) => {
        setProductsLoading(false);

        const formatProducts = res?.data?.map((item) => {
          return {
            ...item,
            name: item.name,
            label: item.name,
            value: item.id,
          };
        });

        setDefaultOptions(formatProducts);

        return formatProducts;
      })
      .catch((err) => {
        setProductsLoading(false);
        console.log(err);
      });
  };

  return (
    <AsyncSelect
      name={`brand_products.[${index}].products`}
      placeholder='Select Product'
      loadOptions={productOptions}
      isAsync={true}
      defaultOptions={defaultOptions}
      onChange={(value) => {
        setValue(`brand_products.[${index}].products`, value);
      }}
      onMenuOpen={() => {
        setMenuWasClosed(false);
      }}
      onMenuClose={() => {
        setMenuWasClosed(true);
        setInputValue('');
      }}
      formatOptionLabel={(product) => {
        return (
          <Box>
            <Text>{product.name}</Text>
          </Box>
        );
      }}
      isLoading={productsLoading}
      isMulti={true}
      value={value}
      menuPlacement={'auto'}
      closeMenuOnSelect={false}
    />
  );
};
