const modelName = "Promoted Categories";
const modelNameSingular = "Promoted Category";
const endpoint = "/corporate_top_selected";

const setup = {
  title: modelName,
  singular: modelNameSingular,
  model: modelName.replace(/\s+/g, "-").toLowerCase(),
  canDelete: false,
  canAddNew: false,
  canSearch: false,
  endpoint,
  accessor: "id",
};

export default setup;
