import setup from "./setup";
import React from "react";
import { ListWrapper, LinkButton } from "@builtbypixel/nucleus";
import { Button, Icon } from "@chakra-ui/react";
import { FaCheck } from "react-icons/fa";

const extraMenu = () => null;

const calculatePercentage = (a, b) => {
  const num = ((parseInt(b, 10) / parseInt(a, 10)) * 100).toFixed(2);

  return isNaN(num) ? "0%" : num + "%";
};

const Impressions = () => {
  const columns = [
    {
      Header: "Ad ID",
      accessor: "interstitialAd_id",
    },
    {
      Header: "Name",
      accessor: "interstitialAd_name",
      id: "edit",
    },
    {
      Header: "Avg Watch Length",
      accessor: "avg_watch_length",
      Cell: ({ value }) => `${value}s`,
    },
    {
      Header: "Impressions",
      accessor: "impression_count",
    },
    {
      Header: "Click Rate % / Clicks",
      accessor: "clicked_count",
      Cell: ({ row, value }) =>
        `${calculatePercentage(
          row.original.impression_count,
          value
        )} / ${value}`,
    },
  ];

  return (
    <ListWrapper
      setup={{
        ...setup,
        title: "Interstitial Ad Impressions",
        canAddNew: false,
        canDelete: false,
        endpoint: "/interstitial-ads-impressions",
      }}
      columns={columns}
      extraMenu={extraMenu}
    />
  );
};

export default Impressions;
