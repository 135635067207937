import { createPageRoutes } from '@builtbypixel/nucleus';
import List from './List';
import Entry from './';
import Home from './Home';
import EmployeeOwnership from './EmployeeOwnership';
import Help from './Help';
import Terms from './Terms';
import Policy from './Policy';
import SymbolGroup from './SymbolGroup';
import OwnLabelRange from './OwnLabelRange';
import WhyBirmingham from './WhyBirmingham';
import WhySheffield from './WhySheffield';
import WhyStockport from './WhyStockport';
import WhySomercotes from './WhySomercotes';
import WhyMiddlesbrough from './WhyMiddlesbrough';
import WhyHalifax from './WhyHalifax';
import WhyAnfield from './WhyAnfield';
import WhyAintree from './WhyAintree';

import setup from './setup';
import PromotedCategories from '../PromotedCategories/components/PromotedCategories';

const pages = [
  {
    id: 'homepage',
    component: Home,
    title: 'Home',
  },
  {
    id: 'terms',
    component: Terms,
    title: 'Terms',
  },
  {
    id: 'policy',
    component: Policy,
    title: 'Policy',
  },
  {
    id: 'help',
    component: Help,
    title: 'Help',
  },
  {
    id: 'employee-ownership',
    component: EmployeeOwnership,
    title: 'Employee Ownership',
  },
  {
    id: 'symbol-group',
    component: SymbolGroup,
    title: 'Symbol Group',
  },
  {
    id: 'own-label-range',
    component: OwnLabelRange,
    title: 'Own Label Range',
  },
  {
    id: 'promoted-categories',
    component: PromotedCategories,
    title: 'Promoted Categories',
  },
  {
    id: 'why-sheffield',
    component: WhySheffield,
    title: 'Why Sheffield',
  },
  {
    id: 'why-birmingham',
    component: WhyBirmingham,
    title: 'Why Birmingham',
  },
  {
    id: 'why-aintree',
    component: WhyAintree,
    title: 'Why Aintree',
  },
  {
    id: 'why-anfield',
    component: WhyAnfield,
    title: 'Why Anfield',
  },
  
  {
    id: 'why-halifax',
    component: WhyHalifax,
    title: 'Why Halifax',
  },
  {
    id: 'why-middlesbrough',
    component: WhyMiddlesbrough,
    title: 'Why Middlesbrough',
  },
  {
    id: 'why-somercotes',
    component: WhySomercotes,
    title: 'Why Somercotes',
  },
  {
    id: 'why-stockport',
    component: WhyStockport,
    title: 'Why Stockport',
  }
];

const crud = createPageRoutes({
  setup,
  list: List,
  pages,
});

const otherRoutes = [
  /*
        {
        path: '/login',
        title: 'Login',
        exact: true,
        auth: false,
        component: Login,
    },
    */
];

const routes = [...crud, ...otherRoutes];

export default routes;
