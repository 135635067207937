import React from "react";
import { Box, Input } from "@chakra-ui/react";

const SearchBar = ({ original, products, setProducts }) => {
  const handleFilterProducts = (e) => {
    if (e.target.value.length === 0) {
      setProducts(original);
    } else {
      const filtered = original.filter((each) => {
        return (
          each?.name?.toLowerCase()?.includes?.(e.target.value.toLowerCase()) ||
          each?.id?.toString()?.includes?.(e.target.value)
        );
      });
      setProducts(filtered);
    }
  };

  return (
    <Box
      minWidth='250px'
      overflow='hidden'
      position='relative'
      overflow='visible'
    >
      <Input onChange={handleFilterProducts} placeholder='Filter products' />
    </Box>
  );
};
export default SearchBar;
