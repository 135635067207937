import React, {useEffect, useState} from 'react'
import { useFormContext, useWatch } from "react-hook-form";
import TimePickerComponent from '../../Nucleus/Fields/TimePicker';
import { Box } from '@chakra-ui/react'
import { de } from 'date-fns/locale';

const AppEventTimePicker = (props) => {

    const { setValue, control } = useFormContext();
    const [selectedDate, setSelectedDate] = useState(null)

    const defaultValue = useWatch({ control, name: props?.name });

    useEffect(() => {
        if(defaultValue) setSelectedDate(new Date(defaultValue))
    }, [defaultValue])

    return (
        <Box mb="5">
        <TimePickerComponent
            placeholder={props.placeholder}
            help={props.help}
            onChange={(val) => {
                setValue(props?.name, val)
                setSelectedDate(val)
            }}
            value={selectedDate}
            showTimeSelect={props.showTimeSelect}
            defaultValue={defaultValue}
            dateFormat={props?.dateFormat}
        />
        </Box>
    )
}

export default AppEventTimePicker