import React, { useMemo, useEffect, useState } from "react";
import { AutoFields, EditView, useHttp } from "@builtbypixel/nucleus";

import setup from "./setup";

const EditForm = () => {

  const [branches, setBranches] = useState([]);
  const Http = useHttp();

  useEffect(() => {
    Http.get(`/branches`).then((res) => {
      setBranches(res?.data?.data);
    });
  }
  , []);

  console.log(branches, "branches")

  const fields = useMemo(
    () => [
      {
        group: "",
        fields: [
          {
            name: "name",
            component: "text",
            placeholder: "Name",
            label: "Name",
            rules: { required: true }

          },
          {
            name: "body",
            component: "textarea",
            placeholder: "Body",
            label: "Body",
            rules: { required: true }
          },
          {
            name: "pdf",
            component: "file",
            placeholder: "PDF",
            label: "PDF",
            rules: { required: true }
          },
          {
            name: "branch",
            component: "select",
            label: "Branches",
            options: branches,
            isMulti: true,
            labelKey: "name",
            valueKey: "id",
            rules: { required: true }
          }
        ],
      },
    ],
    [branches]
  );

  return (
    <EditView setup={setup} isFullWidth={false}>
      <AutoFields fields={fields} />
    </EditView>
  );
};

export default EditForm;
