import React, { useEffect } from 'react';
import { Switch } from '@chakra-ui/react';

const SwitchField = (props) => {
    const { onChange, onBlur, value, defaultValue, ...rest } = props;

    useEffect(() => {
        if (value === '') {
            onChange(false);
        }
    }, [value, onChange]);

    return (
        <Switch
            onChange={(e) => onChange(e.target.checked)}
            onBlur={() => {
                onBlur && onBlur();
            }}
            value={value === true}
            isChecked={value === true}
            required={false}
            isRequired={false}
            defaultValue={defaultValue || false}
            {...rest}
        />
    );
};

export default SwitchField;
