import React from 'react';

import { Box, Container, Grid, GridItem } from '@chakra-ui/react';
import { EditView, Field } from '@builtbypixel/nucleus';
import setup from './setup';
import { useParams } from 'react-router-dom';
import ProductsContainer from "../../components/ProductsContainer";
import WrapperView from "../../components/CustomEditWrapper";

const EditForm = () => {
    const { type } = useParams();

    return (
        <EditView setup={setup}>
            <WrapperView>
                <Field
                    auto
                    name='name'
                    component='text'
                    label='Name'
                    isRequired
                    rules={{ required: true }}
                />
                <Field
                    auto
                    name='image'
                    component='file'
                    label='Image'
                    isRequired
                    rules={{ required: true }}
                />
                <Field
                    auto
                    isFullWidth
                    name='active'
                    component='switch'
                    label='Active'
                />
            </WrapperView>
        </EditView>
    );
};

export default EditForm;
