import React, { useState, useEffect } from "react";

import { useHttp } from "../../../Nucleus/hooks";
import { useFormContext, useFieldArray, useWatch } from "react-hook-form";

import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Text,
  useToast,
} from "@chakra-ui/react";

import AsyncSelect from "react-select/async";

const PromotedCategories = () => {
  const Http = useHttp();
  const [categories, setCategories] = useState([]);
  const [categoryLoading, setCategoryLoading] = useState(false);
  const selectedCategories = useWatch({ name: "promoted-categories" });
  const [newSelectedCategories, setNewSelectedCategories] = useState();
  const [ids, setIds] = useState([]);
  const [preOpt, setPreOpt] = useState();
  const toast = useToast();

  useEffect(() => {
    setCategoryLoading(true);
    Http.get("/categories")
      .then((res) => {
        setCategoryLoading(false);
        append("");
        setCategories(
          res?.data?.data.map((item) => {
            return {
              ...item,
              name: item.name,
              label: item.name,
              value: item.id,
            };
          })
        );
      })
      .catch((err) => {
        setCategoryLoading(false);
        console.log(err);
      });
  }, []);

  useEffect(() => {
    Http.get("/feed-categories/")
      .then((res) => {
        setIds(
          res?.data?.map((resId) => {
            return resId?.id;
          })
        );
        setPreOpt(
          res?.data?.map((item) => {
            return {
              ...item,
              name: item.name,
              label: item.name,
              value: item.id,
            };
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const { register, control, handleSubmit, reset, setValue, getValues } =
    useFormContext({});

  const { fields, append, prepend, remove, swap, move, insert, replace } =
    useFieldArray({
      control,
      name: "promoted-categories",
    });

  const submit = () => {
    Http.put("/feed-categories/", {
      categories: newSelectedCategories?.map((i) => {
        return i.id;
      }),
    })
      .then((res) => {
        toast({
          title: "Success",
          description: "Successfully updated",
          status: "success",
          duration: 5000,
          isClosable: false,
        });
      })
      .catch((err) => {
        toast({
          title: "Error",
          description: `There was a problem with your request. Error: ${err.message}`,
          status: "error",
          duration: null,
          isClosable: true,
        });
      });
  };

  const handleChange = (value, index) => {
    setValue(`promoted-categories.[${index}]`, value);
    setNewSelectedCategories(value);
  };

  useEffect(() => {
    newSelectedCategories?.map((i) => {
      return setIds([...ids, i.id]);
    });
  }, [newSelectedCategories]);
  return (
    <Flex bg="white" direction="column" w="100%" h="100%" m="1em" p="1.5em">
      <Text>Promoted Categories</Text>
      <Button ml="auto" bg="#229652" onClick={() => submit()} color="white">
        Save Promoted Categories
      </Button>
      {fields.map((item, index) => {
        return (
          <Box p="1em" w="100%">
            <Grid key={item.id} templateColumns="80% 4em" gap={2}>
              <GridItem>
                <AsyncSelect
                  name={`promoted-categories.[${index}].name`}
                  placeholder="Select Brand"
                  loadOptions={preOpt}
                  isAsync={true}
                  defaultOptions={categories}
                  isMulti
                  onChange={(value) => {
                    handleChange(value, index);
                  }}
                  defaultValue={preOpt}
                  isLoading={categoryLoading}
                  formatOptionLabel={(category) => (
                    <Box>
                      <Text>{category.name}</Text>
                    </Box>
                  )}
                  value={selectedCategories?.[index]?.brand}
                  menuPlacement={"auto"}
                />
              </GridItem>
            </Grid>
          </Box>
        );
      })}
    </Flex>
  );
};

export default PromotedCategories;
