import React, { useMemo, useEffect, useState } from "react";
import { AutoFields, EditView } from "@builtbypixel/nucleus";
import {useHttp }from "../../Nucleus/hooks/useHttp"

import setup from "./setup";

const EditForm = () => {
  const Http = useHttp()
  const [groups, setGroups] = useState([])
  const [branches, setBranches] = useState([])

  useEffect(() => {
    Http.get("/groups")
    .then(res => {
      setGroups(res.data.data)
    })
    Http.get("/branches")
    .then(res => {
      setBranches(res.data.data)
    })
  },[])

  const fields = useMemo(
    () => [
      {
        group: "",
        fields: [
          {
            name: "name",
            component: "text",
            placeholder: "category name",
            label: "Category name",
          },
          {
            name: "data",
            component: "repeater",
            layout: "vertical",
            fieldArray: [
              {
                name: "scheme",
                label: "scheme",
                component: "text",
                options: groups,
                labelKey: "scheme",
                valueKey: "id",
                selectValue: "value"
                // value: "value"
              },
              {
                name: "branch",
                label: "branch",
                component: "select",
                options: branches,
                labelKey: "name",
                valueKey: "id",
                selectValue: "label"
              },
              {
                name: "resources",
                component: "file",
                isMultiple: true
              },
            ]
          }
        ],
      },
    ],
    [groups, branches]
  );

  return (
    <EditView setup={setup} isFullWidth={true}>
      <AutoFields fields={fields} isFullWidth />
    </EditView>
  );
};

export default EditForm;
