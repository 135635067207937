import React, { useEffect, useState } from "react";
import { Box, Flex, Text, Spinner, Grid, Divider } from "@chakra-ui/react";
import { useHttp } from "../../Nucleus/hooks";
import dayjs from "dayjs";

const ViewCustomers = ({ match }) => {
  const Http = useHttp();
  const id = match.params.id;
  const [customer, setCustomer] = useState(null);

  useEffect(() => {
    Http.get(`customers/${id}`).then((res) => {
      setCustomer(res.data.data);
    });
  }, []);

  if (!customer)
    return (
      <Box mx="auto" my="auto">
        <Spinner size="xl" />
      </Box>
    );

  const {
    business_name,
    email,
    orders_per_week_number,
    last_order: { created_at, total },
    trolley: { total_items, total_price },
  } = customer;

  return (
    <Box p="20px" width="100%">
      <Text fontSize="30px">
        {business_name} ({id})
      </Text>
      <Text>
        Email: <b>{email}</b>
      </Text>
      <Divider mt="20px" />
      <Text fontSize="30px" mt="20px">
        Last Order Details
      </Text>
      <Text>
        Purchase Date: <b>{dayjs(created_at).format("DD/MM/YYYY")}</b> at{" "}
        <b>{dayjs(created_at).format("HH:mm")}</b>
      </Text>
      <Text>Total price: £{total}</Text>
      <Divider mt="20px" />
      {/* <Text fontSize="30px" mt="20px" mb="10px">
        Orders Per Week In the Past Quarter
      </Text>
      <Grid
        templateColumns="1fr 1fr 1fr"
        width="80%"
        bg="#efefef"
        fontWeight="bold"
        textAlign="center"
      >
        <Text>Week</Text>
        <Text>Count</Text>
        <Text>Total</Text>
      </Grid>

      {orders_per_week_number.map((item, index) => {
        return (
          <Grid
            templateColumns="1fr 1fr 1fr"
            width="80%"
            bg={index % 2 !== 0 && "#efefef"}
            textAlign="center"
          >
            <Text>{item.week}</Text>
            <Text>{item.count}</Text>
            <Text>{item.total}</Text>
          </Grid>
        );
      })}
      <Divider mt="20px" /> */}
      <Text fontSize="30px" mt="20px" mb="10px">
        Current Trolley
      </Text>
      {customer.trolley ? (
        <Box>
          <Text>
            Number of items in trolley: <b>{total_items}</b>
          </Text>
          <Text>
            Total price: <b>£{total_price}</b>
          </Text>
        </Box>
      ) : (
        <Text fontWeight="bold">There are no items in the trolley</Text>
      )}
    </Box>
  );
};

export default ViewCustomers;
