import React from 'react'
import { SketchPicker } from 'react-color'
import {
  Flex,
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Text
} from '@chakra-ui/react'

const ColourPicker = (props) => {
  const { onChange, defaultValue, value } = props

  const [colour, setColour] = React.useState((props?.color) ? props?.color : '#000000')

  React.useEffect(() => {
    if (defaultValue) {
      setColour(defaultValue)
    }
  }, [defaultValue])

  return (
    <Flex align='center'>
      <Popover>
        <PopoverTrigger>
          <Box w='100px' h='40px' border="1px solid black" rounded='lg' bg={colour} />
        </PopoverTrigger>
        <PopoverContent>
          <Flex align='center' justify='center' p='10px'>
            <SketchPicker
              color={value}
              onChange={(color) => {
                setColour(color.hex)
                onChange(color.hex)
              }}
            />
          </Flex>
        </PopoverContent>
      </Popover>
      <Text ml='20px'>{value}</Text>
    </Flex>
  )
}

export default ColourPicker
