import React from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Box,
} from "@chakra-ui/react";
import ProductSearch from "../../ProductSearch";
import { FiPlus } from "react-icons/fi";
import { useRecoilState } from "recoil";
import { tableAtom } from "../../../Nucleus/state/global";
import { useFormContext } from "react-hook-form";

const AddProductsModal = ({ original, disable }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [tableState, setTableState] = useRecoilState(tableAtom);
  const { setValue } = useFormContext();

  const handleSave = () => {
    if (tableState.selectedRows.length > 0) {
      let arr = original ? original : [];
      const mergedProducts = arr.concat(tableState.selectedRows);
      setValue("products", mergedProducts);
    }
    onClose();
  };
  return (
    <Box>
      <Button
        variant='solid'
        colorScheme='green'
        leftIcon={<FiPlus />}
        isDisabled={disable}
        onClick={onOpen}
      >
        Add products
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size='full'>
        <ModalOverlay />
        <ModalContent w='80%'>
          <ModalHeader>Find products</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ProductSearch />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='green' onClick={() => handleSave()}>
              Save{" "}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};
export default AddProductsModal;
