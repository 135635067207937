import React from "react";
import { Box, Button, Flex } from "@chakra-ui/react";
import ProductList from "../../containers/Products/List";
import { useRecoilState } from "recoil";
import { tableAtom } from "../../Nucleus/state/global";
import ProductCard from "../ProductsContainer/ProductCard";
import { useState } from "react";

const ProductSearch = () => {
  const [tableState, setTableState] = useRecoilState(tableAtom);
  const [toggleSelected, setToggleSelected] = useState(false);
  const [filterdProductIds, setFilterdProductIds] = useState([]);

  const handleRemoveProduct = (selectedProduct) => {
    const filteredProducts = tableState.selectedRows.filter(
      (each) => each.id !== selectedProduct.id
    );
    const tempFilterdProductIds = [];
    tableState.selectedRows.map((each) => {
      if (each.id !== selectedProduct.id) {
        tempFilterdProductIds.push(each.id);
      }
    });
    setFilterdProductIds(tempFilterdProductIds);
    setTableState((old) => ({
      ...old,
      selected: tempFilterdProductIds,
      selectedRows: filteredProducts,
    }));
  };
  return (
    <Flex direction='column'>
      <Box h='40px' w='100%'>
        {tableState.selectedRows.length > 0 && (
          <Button w='100%' onClick={() => setToggleSelected(!toggleSelected)}>
            {toggleSelected
              ? "Hide selected products"
              : "Show selected products"}
          </Button>
        )}
      </Box>

      <Box display={toggleSelected ? "block" : "none"} overflow='visible'>
        <Flex w='100%' overflowX='scroll' direction='row'>
          {tableState.selectedRows.map((product) => (
            <Box mt='10px'>
              <ProductCard
                product={product}
                handleRemoveProduct={handleRemoveProduct}
                hideRemoveButton
              />
            </Box>
          ))}
        </Flex>
      </Box>

      <Box w='100%'>
        <ProductList customSelected={filterdProductIds} />
      </Box>
    </Flex>
  );
};

export default ProductSearch;
