import React, { useMemo } from 'react';
import { LinkButton, ListWrapper } from '@builtbypixel/nucleus';

import { IconButton } from '@chakra-ui/react';
import { FiEdit } from 'react-icons/fi';
import setup from './setup';
import { Button, Image } from '@chakra-ui/react';
// Extra Menu appears next to the create button
const extraMenu = () => {
    return null;
};

const columns = [
    {
        Header: 'QR Code',
        accessor: 'qr_code',
        Cell: ({ row }) => (
            <Image height="40px" src={row.original.qr_code?.[0].url} />
        ),
    },
    {
        Header: 'Supplier',
        accessor: 'supplier',
        Cell: ({ row }) => (
            row?.original?.supplier?.name
        ),
    },
    {
        Header: 'Event',
        accessor: 'event',
        Cell: ({ row }) => (
            row?.original?.event?.name
        ),
    }
];

const List = () => {
    
    return (
        <ListWrapper
            setup={setup}
            columns={columns}
            extraMenu={extraMenu}
            handleRowSelection={(rows) => console.log(rows)}
        />
    );
};

export default List;
