import React, { useMemo } from 'react';
import { LinkButton, ListWrapper } from '@builtbypixel/nucleus';

import { IconButton } from '@chakra-ui/react';
import { FiEdit } from 'react-icons/fi';
import setup from './setup';
import { Button, Text } from '@chakra-ui/react';
// Extra Menu appears next to the create button
const extraMenu = () => {
    return null;
};

const columns = [
    {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ row }) => (
            <LinkButton to={`${setup.model}/edit/${row.original.id}`}>
              <Button variant="link" variantColor="secondary" rounded="md">
                {row.original.name}
              </Button>
            </LinkButton>
          ),
    },
    {
        Header: 'Position',
        accessor: 'position',
    },
    {
        Header: 'Type',
        accessor: 'type',
        Cell: ({ row }) => <Text>{row?.original?.type?.name}</Text>,
    }
];

const List = () => {
    const filters = useMemo(
        () => [
            // {
            //    name: 'from',
            //    component: 'date',
            //    placeholder: 'From'
            // },
            // {
            //    name: 'isAdmin',
            //    component: 'bool',
            //    label: 'Is Admin'
            // },
            // {
            //    name: 'tester',
            //    component: 'select',
            //    placeholder: 'Tester',
            //    options: [{ name: 'yyuss', id: 1 }],
            //    labelKey: 'name',
            //    valueKey: 'id'
            // }
        ],
        []
    );

    return (
        <ListWrapper
            filters={filters}
            setup={setup}
            columns={columns}
            extraMenu={extraMenu}
            handleRowSelection={(rows) => console.log(rows)}
        />
    );
};

export default List;
