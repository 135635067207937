import React from "react";
import { LinkButton, ListWrapper, useHttp } from "@builtbypixel/nucleus";
import { Button, Text, Icon, Flex, Box, useToast } from "@chakra-ui/react";
import setup from "./setup";
import { FaCheck } from "react-icons/fa";
import { IoCloudOfflineOutline } from "react-icons/io5";
import { BiCheck, BiErrorCircle } from "react-icons/bi";

const extraMenu = () => null;

const List = () => {
  const Http = useHttp();

  const setSingleDropshipping = (item, boolean) => {
    Http.put("/customers", {
      customers: [{ id: item.id, value: boolean }],
    }).then((res) => {
      window.location.reload();
    });
  };

  const setAllDropshipping = (selected, boolean) => {
    const updatedDropshipping = selected.map((id) => {
      return { id: id, value: boolean };
    });
    Http.put("/customers", { customers: updatedDropshipping }).then((res) => {
      window.location.reload();
    });
  };

  const loginAsUser = (email, id, branchid, site) => {
    Http.post(`auth/admin-users/switch-user`, {
      email,
    })
      .then((res) => {

        if (site === "gold") {
          window.open(
            `https://online.golocal.direct?loginAs=${res.data.data.token}&cust=${id}&branchid=${branchid}`,
            "_blank"
          );
        } else {
          window.open(
            `https://online.parfetts.co.uk?loginAs=${res.data.data.token}&cust=${id}&branchid=${branchid}`,
            "_blank"
          );
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const columns = [
    {
      Header: "Customer",
      accessor: "id",
      id: "id",
      Cell: ({ row, value }) => {
        const {
          original: { id, business_name, online, last_activity },
        } = row;
        return (
          <LinkButton to={`${setup.model}/edit/${row.original.id}`}>
          <Button variant="link" variantColor="secondary" rounded="md">
            <Box variant='link' variantColor='secondary' rounded='md'>
              ({id}) {business_name}
              <Flex mt='5px'>
                {online ? (
                  <FaCheck color='green' />
                ) : (
                  <Flex>
                    <IoCloudOfflineOutline />
                    <Text ml='5px'>since {last_activity}</Text>
                  </Flex>
                )}
              </Flex>
            </Box>
          </Button>
        </LinkButton>
        );
      },
    },
    {
      Header: "Scheme",
      accessor: "scheme",
    },
    {
      Header: "Branch",
      accessor: "branch",
      Cell: ({ value }) => value.name,
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Dropshipping Active",
      accessor: "dropship_activated",
      Cell: ({ value, row }) => (
        <Flex>
          {value ? (
            <BiCheck size='2em' color='green' style={{ marginLeft: "15px" }} />
          ) : (
            <Button
              variant='ghost'
              size='sm'
              onClick={() => setSingleDropshipping(row.original, true)}
            >
              Enable
            </Button>
          )}
        </Flex>
      ),
    },
    {
      Header: "Ordering",
      accessor: "is_ordering",
      Cell: ({row }) => (
        row?.original?.is_ordering === true && (
          <Flex>
            <BiCheck size='2em' color='green' style={{ marginLeft: "15px" }} />
          </Flex>
        )
      ),
    },
    {
      Header: "Fascia",
      accessor: "fascia",
      Cell: ({row }) => (
        row?.original?.fascia === 1 && (
          <Flex>
            <BiCheck size='2em' color='green' style={{ marginLeft: "15px" }} />
          </Flex>
        )
      ),
    },
    {
      Header: "Account Status",
      accessor: "account_status",
      Cell: ({row }) => (
        <Text>{row?.original?.account_status}</Text>
      ),
    },
    {
      accessor: "created_at",
      Header: "Login As",
      filterable: false,
      Cell: ({ row }) => (
        <div className='text-center btn-group'>
          <Button
            bg='#b1eaff'
            outline
            size='sm'
            onClick={() =>
              loginAsUser(
                row.original.email,
                row.original.id,
                row.original.branch.id,
                row.original?.site
              )
            }
            style={{ marginRight: 10 }}
          >
            Login As User
          </Button>
        </div>
      ),
    },
    {
      Header: "View in CRM",
      Cell: ({ row }) => (
        <Button bg='#b1eaff'>
          <a
            target='_blank'
            href={`https://crm.parfetts.co.uk/retailers/view/${row.original.branch.id}/${row.original.id}/invoices`}
          >
            View
          </a>
        </Button>
      ),
    },
  ];

  return (
    <ListWrapper
      setup={setup}
      columns={columns}
      extraMenu={extraMenu}
      customFunction={setAllDropshipping}
    />
  );
};

export default List;
