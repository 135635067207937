const modelName = 'Own Label Range';
const modelNameSingular = 'Own Label Range';
const endpoint = '/own-label-range';

const setup = {
  title: modelName,
  singular: modelNameSingular,
  model: modelName.replace(/\s+/g, '-').toLowerCase(),
  canDelete: false,
  canAddNew: false,
  canSearch: false,
  endpoint,
  accessor: 'id',
};

export default setup;
