import React, { useMemo, useEffect, useState } from "react";
import { AutoFields, Field, EditView } from "@builtbypixel/nucleus";
import { useHttp } from "../../Nucleus/hooks";
import { Text, Box, Spinner, Flex } from "@chakra-ui/react";


import setup from "./setup";
import { BiCategory } from "react-icons/bi";


const EditForm = () => {
  const [categories, setCategories] = useState([])
  const [loading, setLoading] = useState(true)
  const Http = useHttp()
  useEffect(() => {
    Http.get("categories/top-level").then((res) => {
      setCategories(res.data)
      setLoading(false)
    })
  },[])

  const fields = useMemo(
    () => [],
    []
  );

  return (
    <EditView setup={setup} isFullWidth={false} isSingle={true} hideHeaderButtons={true}>
      {loading ? (
      <Flex alignItems="center" height="100%">
        <Spinner size="xl"/>
      </Flex>
      ) : (
        <Box width="100%" p="25px">
          <Text fontSize="28px" mb="20px">Homepage Categories</Text>
          <Field
          width="150px"
            name="category"
            component="select"
            label="Category"
            options={categories}
            labelKey="name"
            valueKey="id"
          />
          <Field
            name="amount"
            component="number"
            label="Amount"
            bg="white"
          />
        </Box>
      )}
    </EditView>
  );
};

export default EditForm;