import React, { useMemo } from 'react';
import { AutoFields, EditView } from '@builtbypixel/nucleus';

import setup from '../setup';

const EditForm = () => {
  const fields = useMemo(
    () => [
      {
        group: 'General',
        tab: 'General',
        fields: [
          {
            name: 'name',
            component: 'text',
            placeholder: 'Name',
            label: 'Page Name',
          },
          {
            name: 'url',
            component: 'text',
            placeholder: 'e.g. /products',
            label: 'Page URL',
          },
        ],
      },
      {
        group: 'Banner Image',
        tab: 'Banner Image',
        fields: [
          {
            name: 'content.banner_image',
            component: 'file',
            label: 'Banner Image',
            required: true,
          },
        ],
      },
      {
        group: 'Top Content',
        tab: 'Top Content',
        fields: [
          {
            name: 'content.title',
            component: 'richtext',
            label: 'Title',
            required: true,
          },
          {
            name: 'content.description',
            component: 'richtext',
            label: 'Description',
            required: true,
          },
        ],
      },
      {
        group: 'Content',
        tab: 'Content',
        fields: [
          {
            name: 'content.content',
            component: 'repeater',
            label: 'Content',
            layout: 'vertical',
            fieldArray: [
              {
                name: 'title',
                component: 'richtext',
                label: 'Title',
              },
              {
                name: 'description',
                component: 'richtext',
                label: 'Description',
              },
              {
                name: 'image',
                component: 'file',
                label: 'Image',
              },
            ],
            required: true,
          },
        ],
      },
      {
        group: 'Testimonial',
        tab: 'Testimonial',
        fields: [
          {
            name: 'content.testimonials',
            component: 'repeater',
            label: 'Testimonials',
            layout: 'vertical',
            fieldArray: [
              {
                name: 'name',
                component: 'text',
                label: 'Name',
              },
              {
                name: 'position',
                component: 'text',
                label: 'Position',
              },
              {
                name: 'content',
                component: 'richtext',
                label: 'Description',
              },
              {
                name: 'image',
                component: 'file',
                label: 'Image',
              },
            ],
            required: true,
          },
        ],
      },
      {
        group: 'SEO',
        tab: 'SEO',
        fields: [
          {
            name: 'seo_title',
            component: 'text',
            label: 'SEO title',
          },
          {
            name: 'seo_description',
            component: 'textarea',
            label: 'SEO description',
          },
        ],
      },
    ],
    []
  );

  return (
    <EditView setup={setup} isFullWidth={false}>
      <AutoFields fields={fields} />
    </EditView>
  );
};

export default EditForm;
