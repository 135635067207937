import React from "react";
import { EditView, useHttp, debounce, Field } from "@builtbypixel/nucleus";
import setup from "./setup";
import { Box } from "@chakra-ui/react";

const EditForm = () => {
  const Http = useHttp();

  const handleSearchProducts = debounce((search, callback) => {
    return Http.get(`/products?pagination=9999&search=${search}`).then(
      (res) => {
        callback(res.data.data);
      }
    );
  }, 1000);

  return (
    <EditView setup={setup} isFullWidth>
      <Box bg="white" w="100%" display="block" p={5}>
        <Field name="name" component="text" label="name" />
        <Field
          name="products"
          component="repeater"
          label="Products"
          layout="vertical"
          fieldArray={[
            {
              name: "product",
              component: "select",
              loadOptions: handleSearchProducts,
              rules: { required: true },
              label: "Product",
              labelKey: "name",
              valueKey: "id",
              isAsync: true,
              selectValue: "id",
              isHorizontal: true,
            },
            {
              name: "image_a",
              label: "Image A",
              component: "file",
              max: 1,
              rules: { required: true },
            },
            {
              name: "image_b",
              label: "Image B",
              component: "file",
              max: 1,
              rules: { required: true },
            },
          ]}
        />
      </Box>
    </EditView>
  );
};

export default EditForm;
