import React from "react";
import { Flex, Box, Text } from "@chakra-ui/react";
import Menu from "../../Menu";
import SideBar from "../../Sidebar";

const CustomPage = ({ children }) => {
  return (
    <Flex>
      <SideBar />
      <Menu />
      {children}
    </Flex>
  );
};

export default CustomPage;
