import React, { useEffect, useState } from 'react';
import { useHttp } from '../../Nucleus/hooks/useHttp';
import { Box, Container, Grid, GridItem, Text, Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import { EditView, Field, debounce } from '@builtbypixel/nucleus';
import setup from './setup';
import { useParams } from 'react-router-dom';
import WrapperView from "../../components/CustomEditWrapper";
import AppEventTimePicker from "../../components/AppEventTimePicker/AppEventTimePicker";
import { useFormContext, useWatch } from 'react-hook-form';
const EditForm = () => {
    const { type } = useParams();
    const Http = useHttp()
    const [sites, setSites] = useState([]);
    const [groups, setGroups] = useState([]);

    useEffect(() => {
        Http.get(`/sites`).then(
            (res) => {
                setSites(res?.data)
            }
        )
    }, [])

    useEffect(() => {
        Http.get(`/groups`).then(
            (res) => {
                setGroups(res?.data?.data)
            }
        )
    }, [])

    const handleSearchGroups = debounce((search, callback) => {
        return Http.get(`/groups`).then(
            (res) => {
                callback(res.data.data);
            }
        );
    }, 1000);



    return (
        <EditView setup={setup}>
            <WrapperView>

                <Field
                    auto
                    name='name'
                    component='text'
                    label='Name'
                    isRequired
                    rules={{ required: true }}
                />
                <Grid templateColumns='repeat(2, 1fr)' gap={4}>
                    <GridItem>
                        <Field
                            auto
                            name='groups'
                            component='select'
                            label='Price Scheme'
                            options={groups}
                            isRequired
                            formatOptionLabel={(group) => (
                                <Box>
                                    <Text>{group.scheme}</Text>
                                </Box>
                            )}
                            isMulti={true}
                            labelKey='scheme'
                            valueKey='id'
                            rules={{ required: true }}
                            help="Please Select The Price Scheme Which Will Be Used For This Promotion"
                        />
                    </GridItem>
                    <GridItem>
                        <Field
                            auto
                            name='sites'
                            component='select'
                            label='Site'
                            isRequired
                            rules={{ required: true }}
                            options={sites}
                            formatOptionLabel={(site) => (
                                <Box>
                                    <Text>{site.name}</Text>
                                </Box>
                            )}
                            isMulti={true}
                            labelKey='name'
                            valueKey='id'
                        />
                    </GridItem>
                </Grid>

                <Grid templateColumns='repeat(2, 1fr)' gap={4}>
                    <GridItem>
                        <Text fontSize={11} fontWeight={600} color='gray.600' mb='3px'>Start Date</Text>
                        <AppEventTimePicker
                            placeholder='Start Date'
                            help='Start Date'
                            name='start_date'
                            isRequired
                            rules={{ required: true }}
                            showTimeSelect={false}
                            dateFormat='dd/MM/yyyy'
                        />
                    </GridItem>
                    <GridItem>
                        <Text fontSize={11} fontWeight={600} color='gray.600' mb='3px'>End Date</Text>
                        <AppEventTimePicker
                            placeholder='End Date'
                            help='End Date'
                            name='end_date'
                            isRequired
                            rules={{ required: true }}
                            showTimeSelect={false}
                            dateFormat='dd/MM/yyyy'
                        />
                    </GridItem>
                </Grid>

                <ColourPicker />

                <Field
                    auto
                    isFullWidth
                    name='active'
                    component='switch'
                    label='Active'
                />
            </WrapperView>
        </EditView>
    );
};

const ColourPicker = () => {
    const { setValue } = useFormContext();

    const backgroundColour = useWatch({
        name: 'background_colour',
    });

    const handleChangeColour = (colour) => {
        if (colour) {
            setValue('background_colour', colour)
        }
    }
    return (
        <Field
            auto
            isFullWidth
            name='background_colour'
            component='colour'
            label='Background Colour'
            color={backgroundColour}
            onchange={handleChangeColour}
        />
    )
}
export default EditForm;
