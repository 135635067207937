const modelName = "QR Codes";
const modelNameSingular = "QRCodes";
const endpoint = "/qr_codes";
const setup = {
  title: modelName,
  singular: modelNameSingular,
  model: modelName.replace(/\s+/g, "-").toLowerCase(),
  canDelete: true,
  canAddNew: true,
  canSearch: true,
  endpoint,
  accessor: "id",
};
export default setup;