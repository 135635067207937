/* global localStorage, */

import { useLocation } from 'react-router-dom';

const bypassAuth = process.env.REACT_APP_BYPASS_AUTH === 'true' ? true : false;

export const generateSlugFromSite = () => {
    const siteName = process.env.REACT_APP_SITENAME;
    return siteName.split(' ').join('_');
};

/// ///// Authentication Helpers

export const authToken = JSON.parse(localStorage.getItem(`${generateSlugFromSite()}-token`));

export const hasAuthToken = () => (bypassAuth ? true : !!authToken);

export const checkAuthTokenExpiry = () => {
    const now = new Date();

    if (bypassAuth) {
        localStorage.setItem(
            `${generateSlugFromSite()}-token`,
            JSON.stringify({
                token: 'testToken',
                expiry: now.getTime() + 230400000,
            }),
        );
    } else {
        if (hasAuthToken() && now.getTime() > authToken.expiry) {
            localStorage.removeItem(`${generateSlugFromSite()}-token`);
            return false;
        }
        if (hasAuthToken() && now.getTime() < authToken.expiry) {
            localStorage.setItem(
                `${generateSlugFromSite()}-token`,
                JSON.stringify({ ...authToken, expiry: now.getTime() + 230400000 }),
            );
            return true;
        }
    }
};

export const saveSiteToStorage = (sites) => {
    const hasSiteSaved = localStorage.getItem(`${generateSlugFromSite()}-site`);
    if (!hasSiteSaved) {
        localStorage.setItem(`${generateSlugFromSite()}-site`, JSON.stringify(sites[0]));
        window.location.reload();
    }
};

export const getSite = JSON.parse(localStorage.getItem(`${generateSlugFromSite()}-site`));

export const logout = () => {
    localStorage.removeItem(`${generateSlugFromSite()}-token`);

    window.location.href = '/';
};

export const checkPermissions = ({ has, required }) => {
    if (!has.includes('ALL')) {
        const validate = required.some((v) => has.includes(v));
        if (!validate) {
            return false;
        } else {
            return true;
        }
    } else {
        return true;
    }
};

/// ///// Form Helpers

export const saveFormToStorage = ({ path, values }) => {
    localStorage.setItem(path, JSON.stringify(values));
};

export const getFormFromStorage = ({ path, values }) => {
    return JSON.parse(localStorage.getItem(path));
};

// General

export const downloadFile = (url) => {
    var a = document.createElement('a');
    a.href = url;
    const fileName = url.split('/').pop();
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
};

export function useQuery() {
    return new URLSearchParams(useLocation().search);
}
