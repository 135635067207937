import React from 'react';
import {
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
} from '@chakra-ui/react';

const Number = (props) => {
    const { onChange, onBlur, value, children, defaultValue, type, ...rest } = props;
    return (
        <NumberInput
            onChange={(e) => onChange(e)}
            onBlur={() => {
                onBlur && onBlur();
            }}
            value={value}
            required={false}
            defaultValue={defaultValue}
            isRequired={false}
            minWidth={type === 'number' ? '100px' : '200px'}
            type={type}
            {...rest}
        >
            <NumberInputField />
            <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
            </NumberInputStepper>
        </NumberInput>
    );
};

export default Number;
