const modelName = "Dont miss out";
const modelNameSingular = "product";
const endpoint = "/products";

const setup = {
  title: modelName,
  singular: modelNameSingular,
  model: modelName.replace(/\s+/g, "-").toLowerCase(),
  canDelete: true,
  canAddNew: true,
  canSearch: true,
  endpoint,
  accessor: "id",
};

export default setup;
