import React, { useMemo } from "react";
import { AutoFields, EditView } from "@builtbypixel/nucleus";

import setup from "../setup";

const EditForm = () => {
  const fields = useMemo(
    () => [
      {
        group: "General",
        tab: "General",
        fields: [
          {
            name: "name",
            component: "text",
            placeholder: "Name",
            label: "Page Name",
          },
          {
            name: "url",
            component: "text",
            placeholder: "e.g. /products",
            label: "Page URL",
          },
          {
            name: "content.Help_Type_Wysiwyg",
            component: "richtext",
            label: "Help",
          },
        ],
      },
      {
        group: "SEO",
        tab: "SEO",
        fields: [
          {
            name: "seo_title",
            component: "text",
            label: "SEO title"
          },
          {
            name: "seo_description",
            component: "textarea",
            label: "SEO description"
          }
        ]
      }
    ],
    []
  );

  return (
    <EditView setup={setup} isFullWidth={false}>
      <AutoFields fields={fields} />
    </EditView>
  );
};

export default EditForm;
