import React, { useMemo } from "react";
import { AutoFields, EditView } from "@builtbypixel/nucleus";

import setup from "./setup";

const EditForm = () => {
  const fields = useMemo(
    () => [
      {
        group: "",
        fields: [
          {
            name: "business_name",
            component: "text",
            placeholder: "name",
            label: "Business Name",
          },
          {
            name: "contact_name",
            component: "text",
            placeholder: "contact name",
            label: "Contact Name"
          },
          {
            name: "email",
            component: "text",
            placeholder: "email",
            label: "Email",
          },
          {
            name: "online",
            component: "switch",
            label: "Online",
            isDisabled: "true"
          },
          {
            name: "dropship_activated",
            component: "switch",
            label: "Dropshipping"
          }
        ],
      },
    ],
    []
  );

  return (
    <EditView setup={setup} isFullWidth={false}>
      <AutoFields fields={fields} />
    </EditView>
  );
};

export default EditForm;
