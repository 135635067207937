import React, { useEffect, useState } from "react";
import { EditView, useHttp } from "@builtbypixel/nucleus";

import WrapperView from "../../components/CustomEditWrapper";
import setup from "./setup";
import WatchedField from "./Thresholds";

const EditForm = (props) => {
  const Http = useHttp();
  const [stockText, setStockText] = useState(null);

  useEffect(() => {
    Http.get(`/stock-quantity-text/${props.match.params.id}`).then((res) => {
      setStockText(res.data);
    });
  }, []);

  return (
    <EditView setup={setup} isFullWidth={false}>
      <WrapperView>
        <WatchedField data={stockText?.data} />
      </WrapperView>
    </EditView>
  );
};

export default EditForm;
